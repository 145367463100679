<template>
    <div>
        <div style="user-select: none" class="game-1">
            <div style="width: 100%;height: 3.84vw;background-image: url('/tir/hg_bar.png');background-size: cover">
                <input @click="start" type="button" value="start">
            </div>
            <div id="shootGame1-holder">
                <div style="position: relative;width: 100%;height: 0">
                    <div style="position: absolute; z-index: 1;width: 100%;">
                        <div style="width: 100%;display: flex;justify-content: center">
                            <div id="shootGame1-targets">
                                <img src="/tir/top_bar.png" class="top-bar-img">
                                <div class="top-bar-targets"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <canvas id="shootGame1" style="margin: 0;padding: 0;flex: 0 0"/>
            </div>
            <div class="bottom-game">
                <img src="tir/bottom_bar.png" class="bg-img" ref="shootGame1BottomBgImg">
                <div class="content" ref="shootGame1BottomContent">

                    <div class="patrons">
                        <img src="/tir/ammo.png" class="patrons-bg">
                        <div class="patrons-text-holder">
                            <div class="patrons-text" id="shootGame1-pts"></div>
                        </div>
                    </div>

                    <div class="targets-enemy" id="shootGame1-se">
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/head.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/body.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/leg.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/arm.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/hat.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="targets-legal" id="shootGame1-sl">
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/shoot.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">-3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/miss.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">-2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target-any">
                            <div class="target-top">
                                <img src="/tir/legal.png" class="target">
                                <div class="points-holder-flex">
                                    <div class="points-holder">
                                        <img src="/tir/counter.png">
                                        <div class="counter-holder">
                                            <div class="counter">x 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="target-bottom">
                                <img src="/tir/points.png" style="display: block;margin:0 auto;max-height: 100%;">
                                <div class="points">
                                    <div>
                                        <span class="name">Points:</span>
                                        <span class="value">-5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="result">
                        <img src="/tir/all_points.png" class="result-bg">
                        <div class="result-holder">
                            <div class="result-text" id="shootGame1-scr">15</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Shop ref="shop"/>
        <div class="shootGame1-teaser">
            <img src="/tir/teaser.png" class="teaser-bg">
        </div>
    </div>
</template>

<script>
import ShootGame1 from "@/game/shootGame1";
import Shop from "@/Shop.vue";

export default {
    components: { Shop },
    data: () => ({
        game: null,
    }),
    methods: {
        start() {
            this.game.wantStart();
        },
    },
    mounted() {
        this.game = new ShootGame1(this.$refs.shop.onResult);
    },
    beforeUnmount() {
        this.game.finish();
    }
}
</script>

<style lang="less">

@keyframes shootGame1-targets-kf {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes shootGame1-teaser-kf {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}

.shootGame1-teaser {
    position: fixed;
    top: 0;
    width: 100%;

    .teaser-bg {
        animation-delay: 2s;
        animation-duration: 1s;
        animation-name: shootGame1-teaser-kf;
        animation-fill-mode: forwards;
        position: fixed;
        top: 0;
        width: 100%;
    }
}

.game-1 {
    display: flex;
    flex-direction: column;

    #shootGame1-targets {
        transform: translateY(-20px);
        opacity: 0;
        box-sizing: border-box;
        background-size: cover;
        width: 28vw;
        height: 4vw;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .top-bar-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .top-bar-targets {
            padding-top: 0.8vw;

            img {
                width: 1.34vw
            }
        }
    }

    .targets-show {
        animation-delay: 2s;
        animation-duration: 1s;
        animation-name: shootGame1-targets-kf;
        animation-fill-mode: forwards;
    }

    #shootGame1-holder {
        display: flex;
        flex-direction: column;
    }

    .bottom-game {
        padding: 0;
        margin: 0;
        position: relative;
        width: 100%;
        height: 8.4vw;

        .bg-img {
            display: block;
            width: 100%;
        }

        .content {
            img {
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }

            user-select: none;
            font-family: 'GameFont', serif;
            position: absolute;
            width: 90%;
            height: 100%;
            top: 0;
            left: 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .zero {
                color: #ffffff !important;
            }

            .green {
                color: #13D93E !important;
            }

            .red {
                color: #FF0C25 !important;
            }

            .patrons {
                flex: 0 0 13%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                .patrons-bg {
                    width: 100%;
                    display: block;
                }

                .patrons-text-holder {
                    position: absolute;
                    top: 35%;
                    left: 39%;
                    width: 56%;
                    height: 49%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .patrons-text {
                    font-size: 1.5vw;
                    color: #ffffff;
                }
            }

            .targets-enemy {
                flex: 0 0 36%;
                display: flex;
                height: 80%;

                .target-any {
                    flex: 0 0 20%;
                }
            }

            .targets-legal {
                flex: 0 0 18%;
                display: flex;
                height: 80%;

                .target-any {
                    flex: 0 0 33%;

                    .target-top {
                        align-items: center;

                        .target {
                            max-height: 80%;
                        }
                    }
                }
            }

            .target-any {
                display: block;
                height: 100%;
                box-sizing: border-box;
                padding: 0 10px;

                .target-top {
                    height: 70%;
                    display: flex;

                    .target {
                        display: block;
                        flex: 0 0 60%;
                        max-height: 100%;
                        object-fit: scale-down;
                    }

                    .points-holder-flex {
                        flex: 0 0 40%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .points-holder {
                            position: relative;

                            img {
                                display: block;
                                width: 100%;
                            }

                            .counter-holder {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                div {
                                    text-align: center;
                                    font-size: 1vw;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .target-bottom {
                    height: 30%;
                    position: relative;

                    .points {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        div {
                            font-size: 1.2vw;

                            .name {
                                color: #aaa;
                            }

                            .value {
                                padding-left: 0.3vw;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .result {
                flex: 0 0 8%;
                height: 90%;
                position: relative;

                .result-bg {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }

                .result-holder {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .result-text {
                        font-size: 3vw;
                        color: #13D93E;
                    }
                }
            }
        }
    }
}

</style>