const Texture = require("@/game/Texture");


class Atlas extends Texture {
    constructor(gl) {
        super(gl, 'tir3/atlas.png');

        const size = 2048;

        // left, top, right, bottom
        this.elements = {
            gun: [1789, 648, 1945, 786],
            splash: [0, 0, 2048, 2048],
            target: [1785, 130, 1945, 207],
            shoot: [1785, 327, 1945, 440],
            bg1: [30, 22, 1694, 571],
            bg2: [30, 591, 1694, 1140],
        };

        for (const e of Object.keys(this.elements))
            this.elements[e] = this.__processArray(this.elements[e], size);
    }

    __processArray(arr, size) {
        const cp = arr.map(e => e / size);
        const w = cp[2] - cp[0], h = cp[3] - cp[1];
        return { x: cp[0], y: cp[1], w, h, ar: h / w };
    }
}


module.exports = Atlas;