const socket = require("@/game/sock");
const Texture = require("@/game/Texture");
const BackgroundShader = require("@/game/tir2/BackgroundShader");
const QuadMesh = require("@/game/QuadMesh");
const Atlas = require("@/game/tir2/Atlas");
const CenterShader = require("@/game/tir2/CenterShader");
const TraceSpaceShader = require("@/game/tir2/TraceSpaceShader");

let __game2 = null;

const __caller = () => __game2.draw();
const __resize = () => __game2.resize();
const __mousemove = (e) => __game2.mouseMove(e);
const __mousedown = (e) => __game2.mouseClick(e);
const __processSocket = (e) => __game2.processSocket(e);


class ShootGame2 {
    constructor(resultCallback) {
        this.resultCallback = resultCallback;
        this.canvas = document.getElementById('shootGame2');
        this.gl = this.canvas.getContext('webgl');

        this.loadResources();

        this.aspectRatio = 0.33;
        this.resize();

        __game2 = this;

        this.mouse = {
            x: .5,
            y: .5,
            dx: 0,
            dy: 0,
            __prev: null,
            clicks: [],
        };

        this.aim = {
            size: 0,
            prevTime: 0,
            triggerTime: 0,
            move: false,
            prevMove: false,
            maxSize: .045,
        }

        for (let i = 0; i < 6; i++)
            this.mouse.clicks.push({ x: 0, y: 0, time: -100 });

        window.addEventListener('resize', __resize);

        window.requestAnimationFrame(__caller);

        const holder = document.getElementById('shootGame2-holder');
        holder.addEventListener('mousemove', __mousemove);
        holder.addEventListener('mousedown', __mousedown);

        this.dom = {
            holder,
            targets: document.getElementById('game-2-targets'),
            reaction: document.getElementById('game-2-reaction'),
            score: document.getElementById('game-2-score'),
            success: document.getElementById('game-2-success'),
            miss: document.getElementById('game-2-miss'),
            milk: document.getElementById('game-2-milk'),
            ammo: document.getElementById('game-2-ammo'),
            onscreen: document.getElementById('game-2-onscreen'),
        };

        this.start = performance.now() / 1000;
        this.timeStart = this.start;
        this.prevTime = this.start;
        this.serverStart = 0;

        this.playing = false;
        this.timeEnd = -10;

        this.targets = [];
        this.removed = [];

        this.traces = [];

        this.median = {
            sum: 0,
            count: 0
        }

        this.planets = [];
        this.planetsCount = 30;
        const time = this.getNow(true);
        for (let i = 0; i < this.planetsCount; i++)
            this.planets.push(this.generatePlanet(time));

        this.stats = {
            success: 0,
            milk: 0,
            timeout: 0,
            score: 0,
            targets: 20,
            ammo: 20,
        }

        this.sound = {
            background: new Audio('/tir2/background.mp3'),
            new: new Audio('/tir2/new.mp3'),
            shoot: new Audio('/tir2/shoot.mp3'),
            success: new Audio('/tir2/success.mp3'),
            miss: new Audio('/tir2/miss.mp3'),
        }

        socket.setListener(__processSocket);
    }

    generatePlanet(time) {
        let size = Math.random() * 0.3;
        const radius = Math.random();
        const radius2 = (radius + 0.9) * 5;
        return {
            type: Math.floor(Math.random() * 11),
            size: size / radius2,
            centerX: 0.63,
            centerY: 0.12,
            radius,
            ecliptic: 0.3 + (Math.random() - 0.5) * 0.5,
            timeOffset: Math.random() * 6.28,
            timeScale: 1 / (radius2 * radius2 * radius2 / 10) * (Math.random() > 0.5 ? 1 : -1),
            timeStart: time,
            timeEnd: 0
        };
    }

    loadResources() {
        this.background = new Texture(this.gl, 'tir2/bg.png', true);
        this.noise = new Texture(this.gl, 'noise.png', false, true);
        this.bgShader = new BackgroundShader(this.gl).compile();
        this.shader = new CenterShader(this.gl).compile();
        this.quadMesh = new QuadMesh(this.gl);
        this.atlas = new Atlas(this.gl);
        this.traceShader = new TraceSpaceShader(this.gl).compile();
    }

    getServerTime(time) {
        return time - this.timeStart + this.serverStart;
    }


    resize() {
        const scale = window.devicePixelRatio;
        const width = Math.floor(window.innerWidth) - 1;
        const height = Math.floor(window.innerWidth * this.aspectRatio) - 1;

        const renderWidth = Math.floor(window.innerWidth * scale);
        const renderHeight = Math.floor(window.innerWidth * scale * this.aspectRatio);

        this.width = renderWidth;
        this.height = renderHeight;

        this.canvasSize = { width, height };

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;
        this.canvas.style.width = `100%`;
        this.canvas.style.height = `${height}px`;
    }

    wantStart() {
        const b = new ArrayBuffer(Math.floor(Math.random() * 40 + 5));
        const v = new Uint8Array(b);
        for (let i = 0; i < b.byteLength; i++)
            v[i] = Math.floor(Math.random() * 255);
        socket.send(84, v);
    }

    addResult(x, y, score, time = 0) {
        const b = document.createElement('div');
        b.className = 'block';
        b.style.left = `${Math.round(this.canvasSize.width * (x - 0.02))}px`;
        b.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.02))}px`;

        if (time !== 0) {
            const s = document.createElement('div');
            s.innerText = `${Math.round(time * 1000) / 1000}`;
            s.className = 'time';
            b.append(s);
        }

        const s = document.createElement('div');
        s.innerText = `${score}`;
        s.classList.add('text');
        score && s.classList.add(score > 0 ? 'green' : 'red');
        b.append(s);
        this.dom.onscreen.append(b);
        setTimeout(() => b.remove(), 2000);
    }

    addInfo(x, y) {
        const b = document.createElement('div');
        b.className = 'info';
        b.style.left = `${Math.round(this.canvasSize.width * (x - 0.05))}px`;
        b.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.02))}px`;

        const top = document.createElement('div'),
            line = document.createElement('div'),
            dot = document.createElement('div'),
            bottom = document.createElement('div');

        line.className = 'line';
        dot.className = 'dot';

        line.append(dot);
        b.append(top, line, bottom);
        this.dom.onscreen.append(b);
        return b;
    }


    processSocket(e) {
        e.data.arrayBuffer().then(ab => {
            const view = socket.p(ab);

            switch (view.getUint8(0)) {
                case 74:
                    this.finishPlay(view);
                    break;
                case 84:
                    this.startPlay(view);
                    break;
                case 8: {
                    const x = view.getInt16(1) / 16384;
                    const y = view.getInt16(3) / 16384;
                    const score = view.getInt8(5);
                    const time = view.getFloat32(6);
                    const id = view.getUint8(10);
                    this.stats.ammo = view.getUint8(11);

                    const now = this.getNow();
                    if (id !== 0xff) {
                        const t = this.targets.find(e => e.id === id);
                        if (t) {
                            t.timeEnd = now + 0.15;
                            t.shoot = true;
                            t.dom?.classList.add('hide-info');
                        }

                        this.median.sum += time;
                        this.median.count++;
                    }

                    this.addResult(x, y, score, time);

                    const { offsetX, offsetY, delta, size } = this.getGunPosition();
                    const gunY = offsetY + size * 0.2;
                    const leftX = 0.5 - delta + offsetX + size * 0.4;
                    const rightX = 0.5 + delta + offsetX - size * 0.4;

                    const dy = y - gunY;
                    const ldx = x - leftX;
                    const rdx = x - rightX;

                    this.traces.push({ x: leftX + ldx * 0.5, y: gunY + dy * 0.5, length: Math.sqrt(ldx * ldx + dy * dy), rotation: Math.atan2(dy, ldx), start: now });
                    this.traces.push({ x: rightX + rdx * 0.5, y: gunY + dy * 0.5, length: Math.sqrt(rdx * rdx + dy * dy), rotation: Math.atan2(dy, rdx), start: now });
                    this.updateScores();

                    new Promise(async () => {
                        const a = this.sound[score > 0 ? 'success' : 'shoot'].cloneNode();
                        await a.play();
                        a.remove();
                    });
                }
                    break;
                case 76: {
                    const now = this.getNow();
                    const newTargetsLength = view.getUint8(1);
                    const removedLength = view.getUint8(2);

                    let ptr = 3;

                    for (let i = 0; i < newTargetsLength; i++) {
                        this.targets.push({
                            id: view.getUint8(ptr++),
                            type: view.getUint8(ptr++),
                            serverStart: view.getFloat64(ptr),
                            size: view.getFloat32(ptr + 8),
                            prevPotential: 5,
                            times: [0, 2, 4, 6].map(offset => view.getUint16(ptr + 12 + offset) / 1000),
                            lifeTime: view.getFloat32(ptr + 20),
                            startX: view.getFloat32(ptr + 24),
                            startY: view.getFloat32(ptr + 28),
                            endX: view.getFloat32(ptr + 32),
                            endY: view.getFloat32(ptr + 36),
                            params: {
                                xa: view.getUint8(ptr + 40) / 100,
                                xb: view.getUint8(ptr + 41) / 100,
                                xc: view.getUint8(ptr + 42) / 100,
                                ya: view.getUint8(ptr + 43) / 100,
                                yb: view.getUint8(ptr + 44) / 100,
                                yc: view.getUint8(ptr + 45) / 100,
                                a: view.getUint8(ptr + 46) / 100,
                                b: view.getUint8(ptr + 47) / 100,
                            },
                            dom: this.addInfo(0, 0),
                            timeStart: now,
                            timeEnd: 0,
                            timeOffset: Math.random() * 6.28
                        });
                        ptr += 48;
                    }

                    for (let i = 0; i < removedLength; i++) {
                        this.removed.push({
                            id: view.getUint8(ptr++),
                            shoot: view.getUint8(ptr++),
                            score: view.getInt8(ptr++),
                        })
                    }

                    this.stats.success = view.getUint8(ptr++);
                    this.stats.miss = view.getUint8(ptr++);
                    this.stats.milk = view.getUint8(ptr++);
                    this.stats.score = view.getInt8(ptr++);
                    this.stats.ammo = view.getInt8(ptr++);
                    this.stats.targets = view.getInt8(ptr++);

                    if (newTargetsLength > 0) {
                        // new Promise(async () => {
                        //     const a = this.sound.new.cloneNode();
                        //     await a.play();
                        //     a.remove();
                        // });
                    }

                    if (this.removed.some(e => e.score < 0)) {
                        new Promise(async () => {
                            const a = this.sound.miss.cloneNode();
                            await a.play();
                            a.remove();
                        });
                    }

                    this.updateScores();
                }
                    break;
            }
        });
    }

    finish() {

    }

    startPlay(view) {
        this.playing = true;
        this.timeEnd = 0;
        this.serverStart = view.getFloat64(1);
        this.sound.background.currentTime = 0;
        this.sound.background.play();

        this.targets = [];
        this.removed = [];
        this.median.count = 0;
        this.median.sum = 0;

        this.stats.milk = 0;
        this.stats.miss = 0;
        this.stats.success = 0;
        this.stats.left = 20;
        this.stats.ammo = 20;

        this.timeStart = this.getNow();

        this.updateScores();
        this.dom.holder.classList.add('no-cursor');
        this.dom.onscreen.innerHTML = '';
    }

    finishPlay(view) {
        const time = this.getNow();
        this.timeEnd = time;
        this.playing = false;
        this.targets.forEach(e => {
            if (!e.timeEnd)
                e.timeEnd = time
        });
        this.dom.holder.classList.remove('no-cursor');
        const win = view.getUint32(1);
        const score = view.getInt8(5);
        if (this.resultCallback)
            this.resultCallback({ win, score });
    }

    updateScores() {
        this.dom.targets.innerText = `${this.stats.targets}`;
        this.dom.reaction.innerText = this.median.count === 0 ? '0.00' : `${(Math.round(this.median.sum / this.median.count * 100) / 100).toFixed(2)}`;
        this.dom.score.innerText = `${this.stats.score}`;
        this.dom.success.innerText = `${this.stats.success}`;
        this.dom.miss.innerText = `${this.stats.miss}`;
        this.dom.milk.innerText = `${this.stats.milk}`;
        this.dom.ammo.innerText = `${this.stats.ammo}`;
    }

    mouseMove(e) {
        const cs = this.canvas.getBoundingClientRect();
        this.mouse.x = (e.pageX - cs.x) / cs.width;
        this.mouse.y = this.aspectRatio - (e.pageY - cs.y) / cs.width;
    }

    mouseClick(e) {
        if (!this.playing || e.button !== 0)
            return;

        const cs = this.canvas.getBoundingClientRect();
        const x = (e.pageX - cs.x) / cs.width;
        const y = this.aspectRatio - (e.pageY - cs.y) / cs.width;
        this.shootPlanet(x, y);
        for (let i = this.mouse.clicks.length - 1; i > 0; i--)
            this.mouse.clicks[i] = this.mouse.clicks[i - 1];
        this.mouse.clicks[0] = { x, y, time: this.getNow() };

        this.mouse.x = x;
        this.mouse.y = y;

        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        const now = this.getNow();
        this.moveCursor(now, this.mouse.dx, this.mouse.dy);

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        const a = new ArrayBuffer(12);
        const b = new DataView(a);
        b.setInt16(0, Math.round(x * 16384));
        b.setInt16(2, Math.round(y * 16384));
        b.setFloat64(4, this.getServerTime(now));
        socket.send(8, a);
    }

    moveCursor(time, dx, dy) {
        // if (!this.playing)
        //     return;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const move = dist > 0;

        if (dist > 0.05) {
            this.aim.size = Math.max(this.aim.size, 0.8);
            this.aim.move = true;
            this.aim.prevMove = true;
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
        }

        if (move && !this.aim.move || !move && this.aim.move) {
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
            this.aim.move = move;
        }
        else {
            const dt = (time - this.aim.prevTime) * ((time - this.aim.triggerTime > .15 || this.aim.move) ? 1 : 0);
            // this.aim.size = 0*Math.min(1, Math.max(0, this.aim.size + dt * (this.aim.move ? dist * 500 : -5)));
            this.aim.size = Math.min(1, Math.max(0, this.aim.size + dt / (this.aim.prevMove ? .2 : .2) * (this.aim.move ? 1 : -1)));
            this.aim.prevTime = time;
        }

        this.aim.prevMove = this.aim.move;
    }

    shootPlanet(x, y) {
        const time = this.getNow();

        for (let i = 0; i < this.planets.length; i++) {
            const planet = this.planets[i];

            const size = planet.size * .5;
            const t = time * planet.timeScale + planet.timeOffset;
            const localX = Math.cos(t) * planet.radius;
            const localY = Math.sin(t) * planet.radius;
            const z = localY * Math.cos(planet.ecliptic) + 0.5;
            const dx = localX / z + planet.centerX - x;
            const dy = localY * Math.sin(planet.ecliptic) / z + planet.centerY - y;

            if (dx * dx + dy * dy < size * size && !planet.timeEnd)
                planet.timeEnd = time + 0.15;
        }
    }


    drawQuad(texCrop, x, y, z, w, h, opaque = 1) {
        const gl = this.gl;
        this.shader.bindUniforms(x, y, z, w, h, texCrop, opaque);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    getNow() {
        return performance.now() / 1000 - this.start;
    }


    getUfoPos(percent, ufo) {
        const p = Math.min(1, Math.max(0, percent));
        const pPos = (3 * p - 2 * p * p) * p;
        const x = ufo.startX + (ufo.endX - ufo.startX) * pPos;
        const y = ufo.startY + (ufo.endY - ufo.startY) * pPos;
        const sizeScale = 1 - (p - 0.5) * (p - 0.5);

        return { x, y, sizeScale };
    }

    drawPlanetsAndUfo(time) {
        const buffer = [];
        this.planets.forEach(planet => {
            const atlas = this.atlas.elements.planet[planet.type];
            const visibility = (planet.timeEnd ? Math.max(0, Math.min(1, 1. - (time - planet.timeEnd) * 8)) : 1) * Math.min(1, (time - planet.timeStart) * 8);
            const size = planet.size * visibility;
            const t = time * planet.timeScale + planet.timeOffset;
            const localX = Math.cos(t) * planet.radius;
            const localY = Math.sin(t) * planet.radius;
            const z = localY * Math.cos(planet.ecliptic) + 0.5;
            const x = localX / z + planet.centerX;
            const y = localY * Math.sin(planet.ecliptic) / z + planet.centerY;
            buffer.push({ atlas, x, y, z, w: size / z, h: size * atlas.ar / z, opaque: visibility });
        });
        buffer.sort((a, b) => b.z - a.z);
        buffer.forEach(e => this.drawQuad(e.atlas, e.x, e.y, e.z, e.w, e.h, e.opaque));

        const serverTime = this.getServerTime(time);
        this.targets.forEach(ufo => {
            const atlas = this.atlas.elements.ufo[ufo.type];
            const visibility = (ufo.timeEnd ? Math.max(0, Math.min(1, 1. - (time - ufo.timeEnd) * 8)) : 1) * Math.min(1, (time - ufo.timeStart) * 8);
            const { x, y, sizeScale } = this.getUfoPos((serverTime - ufo.serverStart) / ufo.lifeTime, ufo);
            const size = ufo.size * visibility * sizeScale;
            this.drawQuad(atlas, x, y, 0, size, size * atlas.ar, visibility);

            if (ufo.shoot) {
                const atlasBurst = this.atlas.elements.ufoBurst[ufo.type];
                const visibility = (Math.max(ufo.timeEnd, time) - ufo.timeEnd) * 8;
                const size = ufo.size * visibility * sizeScale;
                this.drawQuad(atlasBurst, x, y, 0, size, size * atlasBurst.ar, Math.max(0, Math.min(1, 2 - visibility * .5)));
            }
        });
    }

    getGunPosition() {
        return {
            offsetX: (this.mouse.x * 2 - 1) * 0.2,
            offsetY: -0.03 + (this.mouse.y * 2 - this.aspectRatio) * 0.15,
            delta: 0.2,
            size: 0.2,
        }
    }

    drawGuns(time) {
        const gun = this.atlas.elements.scene.gun;
        const { offsetX, offsetY, delta, size } = this.getGunPosition();
        const endOffset = this.timeEnd ? (time - this.timeEnd) : 0;
        this.drawQuad(gun, 0.5 - delta + offsetX, offsetY - endOffset, 0, size, size * gun.ar, 1);
        this.drawQuad(gun, 0.5 + delta + offsetX, offsetY - endOffset, 0, -size, size * gun.ar, 1);
    }

    drawTraces(time) {
        const gl = this.gl;
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        this.traceShader.bind(this.aspectRatio, 1);
        this.quadMesh.prepareDraw(this.traceShader.attributions.position);

        for (let i = 0; i < this.traces.length; i++) {
            const trace = this.traces[i];
            const percent = Math.min(1, (time - trace.start) / .2);
            this.traceShader.bindUniforms(trace.x, trace.y, trace.length + Math.min(.1, trace.length * .02), trace.rotation, percent, time);

            if (percent > 0.99)
                this.traces.splice(i, 1);

            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        }
    }

    drawAim(time) {
        const aim = this.atlas.elements.scene.aim;
        const side = this.atlas.elements.scene.side;
        const size = (this.aim.size * 0.6 + 0.4) * this.aim.maxSize;
        const sideSize = 0.25 * this.aim.maxSize;
        const sideDelta = size * (.7 + this.aim.size * .2);
        const visibility = Math.min(1, Math.max(0, this.timeEnd ? 1 - (time - this.timeEnd) * 2 : 1.));
        this.drawQuad(aim, this.mouse.x, this.mouse.y, 0, size, size * aim.ar, visibility);
        this.drawQuad(side, this.mouse.x - sideDelta, this.mouse.y, 0, -sideSize, sideSize * side.ar, visibility);
        this.drawQuad(side, this.mouse.x + sideDelta, this.mouse.y, 0, sideSize, sideSize * side.ar, visibility);
    }

    updateInfo(time) {
        const serverTime = this.getServerTime(time);
        this.targets.forEach(e => {
            const timeDelta = serverTime - e.serverStart;
            let score = 1;
            for (let i = 0; i < 4; i++) {
                if (timeDelta < e.times[i]) {
                    score = 5 - i;
                    break;
                }
            }
            const { x, y } = this.getUfoPos((serverTime - e.serverStart) / e.lifeTime, e);
            e.dom.style.left = `${Math.round(this.canvasSize.width * (x - 0.07))}px`;
            e.dom.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.01))}px`;
            e.dom.childNodes[0].innerText = `${timeDelta.toFixed(1)} м/с`;
            e.dom.childNodes[2].innerText = `+${score} points`;
        });
    }

    draw() {
        if (this.mouse.__prev === null)
            this.mouse.__prev = { x: this.mouse.x, y: this.mouse.y };
        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        const time = this.getNow();
        this.moveCursor(this.getNow(), this.mouse.dx, this.mouse.dy);

        for (let i = this.planets.length - 1; i >= 0; i--) {
            if (this.planets[i].timeEnd && time - this.planets[i].timeEnd > 0.2)
                this.planets.splice(i, 1);
        }

        while (this.planets.length < this.planetsCount)
            this.planets.push(this.generatePlanet(time));

        this.targets.forEach(e => {
            const rm = this.removed.find(u => e.id === u.id);
            if (rm && !e.timeEnd) {
                e.timeEnd = time;
                e.shoot = rm.shoot;

                if (!rm.shoot)
                    this.addResult(e.x, e.y, rm.score);
            }
        });
        this.removed = [];

        for (let i = this.targets.length - 1; i >= 0; i--) {
            if (this.targets[i].timeEnd) {
                this.targets[i].dom?.remove();
                if (time - this.targets[i].timeEnd > 1)
                    this.targets.splice(i, 1);
            }
        }

        const gl = this.gl;
        gl.viewport(0, 0, this.width, this.height);
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.enable(gl.BLEND);
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.blendEquation(gl.FUNC_ADD);

        this.noise.bind(1);

        this.quadMesh.prepareDraw(this.shader.attributions.position);

        const P = this.playing ? Math.min(1, time - this.timeStart) : Math.max(0, this.timeEnd + 1. - time);
        this.sound.background.volume = P;
        this.bgShader.bind(this.aspectRatio, 2, 1, time, P);
        this.background.bind(2);
        this.bgShader.bindUniforms(0, 0, 0, 1, this.aspectRatio, { x: 0, y: 0, w: 1, h: 1 });
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        this.shader.bind(this.aspectRatio, 0, 1);
        this.atlas.bind(0);
        this.drawPlanetsAndUfo(time);


        this.drawTraces(time);

        if (!this.timeEnd || this.timeEnd + 1. > time) {
            this.updateInfo(time);
            this.shader.bind(this.aspectRatio, 0);
            this.drawGuns(time);
            this.drawAim(time);
        }

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        this.prevTime = time;
        requestAnimationFrame(__caller);
    }
}


module.exports = ShootGame2;