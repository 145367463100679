const socket = require("@/game/sock");
const QuadMesh = require("@/game/QuadMesh");
const Atlas = require("@/game/tir3/Atlas");
const CenterShader = require("@/game/tir2/CenterShader");
const ModelShader = require("@/game/ModelShader");
const AimShader = require("@/game/tir3/AimShader");
const Texture = require("@/game/Texture");
const { Matrix, Model, Vector, Quaternion } = require("@/game/Model");
const TraceShader = require("@/game/TraceShader");

let __game2 = null;

const __caller = () => __game2.draw();
const __resize = () => __game2.resize();
const __mousemove = (e) => __game2.mouseMove(e);
const __mousedown = (e) => __game2.mouseClick(e);
const __processSocket = (e) => __game2.processSocket(e);


class ShootGame3 {
    constructor(resultCallback) {
        this.resultCallback = resultCallback;
        this.canvas = document.getElementById('shootGame2');
        this.gl = this.canvas.getContext('webgl');

        this.loadResources();

        this.aspectRatio = 0.33;
        this.resize();

        __game2 = this;

        this.mouse = {
            x: .5,
            y: .5,
            dx: 0,
            dy: 0,
            __prev: null,
        };

        this.traces = [];

        window.addEventListener('resize', __resize);

        window.requestAnimationFrame(__caller);

        const holder = document.getElementById('shootGame2-holder');
        holder.addEventListener('mousemove', __mousemove);
        holder.addEventListener('mousedown', __mousedown);

        this.dom = {
            holder,
            targets: document.getElementById('game-3-targets'),
            reaction: document.getElementById('game-3-reaction'),
            score: document.getElementById('game-3-score'),
            success: document.getElementById('game-3-success'),
            miss: document.getElementById('game-3-miss'),
            ammo: document.getElementById('game-3-ammo'),
            onscreen: document.getElementById('game-3-onscreen'),
        };

        this.start = performance.now() / 1000;
        this.timeStart = this.start;
        this.prevTime = this.start;
        this.serverStart = 0;

        this.playing = false;
        this.timeEnd = -10;

        this.targets = [];
        this.removed = [];

        this.traces = [];

        this.median = {
            sum: 0,
            count: 0
        }

        this.stats = {
            success: 0,
            milk: 0,
            timeout: 0,
            score: 0,
            targets: 20,
            ammo: 20,
        }

        this.sound = {
            shoot: new Audio('/tir3/shoot.mp3'),
            success: new Audio('/tir3/success.mp3'),
            new: new Audio('/tir3/new.mp3'),
            // background: new Audio('/tir3/background.mp3'),
        }

        socket.setListener(__processSocket);
    }

    loadResources() {
        this.shader = new CenterShader(this.gl).compile();
        this.quadMesh = new QuadMesh(this.gl);
        this.atlas = new Atlas(this.gl);

        this.aim = {
            shader: new AimShader(this.gl).compile(),
            size: 0,
            prevTime: 0,
            triggerTime: 0,
            move: false,
            prevMove: false,
            maxSize: .1,
        }


        this.traceShader = new TraceShader(this.gl).compile();
        this.gun = {
            model: null,
            actions: {},
            shader: new ModelShader(this.gl).compile(),
            textures: {
                albedo: new Texture(this.gl, 'tir3/gun_albedo.png', true),
                normal: new Texture(this.gl, 'tir3/gun_normal.png', true),
                phys: new Texture(this.gl, 'tir3/gun_phys.png', true),
            },
            node: null,
            identityMatrix: new Matrix(),
        }
        this.projectView = new Matrix();

        const req = new XMLHttpRequest();
        req.open('GET', '/tir3/shotgun.model', true);
        req.responseType = 'blob';
        req.onload = (event) => {
            req.response.arrayBuffer().then(blob => {
                this.gun.model = new Model(blob);
                this.gun.actions = this.gun.model.actions;
                const node = this.gun.model.getNode('Armature');
                this.gun.node = node;
                node.mesh = this.gun.node.children[0].mesh;
                node.storeMeshToGpu(this.gl);
                node.children = [];
                node.armature.startAction(this.gun.actions['Begin']);
                node.pos.z = -1;
                const scale = 0.7;
                node.scale = new Vector(scale, scale, scale);
                node.rot = new Quaternion(1, 0, 0, 0);
            })
        };

        req.send();
    }


    resize() {
        const scale = window.devicePixelRatio;
        const width = Math.floor(window.innerWidth) - 1;
        const height = Math.floor(window.innerWidth * this.aspectRatio) - 1;

        const renderWidth = Math.floor(window.innerWidth * scale);
        const renderHeight = Math.floor(window.innerWidth * scale * this.aspectRatio);

        this.width = renderWidth;
        this.height = renderHeight;

        this.canvasSize = { width, height };

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;
        this.canvas.style.width = `100%`;
        this.canvas.style.height = `${height}px`;
    }

    wantStart() {
        const b = new ArrayBuffer(Math.floor(Math.random() * 40 + 5));
        const v = new Uint8Array(b);
        for (let i = 0; i < b.byteLength; i++)
            v[i] = Math.floor(Math.random() * 255);
        socket.send(62, v);
    }

    addResult(x, y, score, time = 0) {
        const b = document.createElement('div');
        b.className = 'block';
        b.style.left = `${Math.round(this.canvasSize.width * (x - 0.02))}px`;
        b.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.02))}px`;

        if (time !== 0) {
            const s = document.createElement('div');
            s.innerText = `${Math.round(time * 1000) / 1000}`;
            s.className = 'time';
            b.append(s);
        }

        const s = document.createElement('div');
        s.innerText = `${score}`;
        s.classList.add('text');
        score && s.classList.add(score > 0 ? 'green' : 'red');
        b.append(s);
        this.dom.onscreen.append(b);
        setTimeout(() => b?.remove(), 2000);
    }

    addInfo(x, y) {
        const b = document.createElement('div');
        b.className = 'info';
        b.style.left = `${Math.round(this.canvasSize.width * (x - 0.07))}px`;
        b.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.01))}px`;

        const top = document.createElement('div'),
            line = document.createElement('div'),
            bottom = document.createElement('div');

        line.className = 'line';

        b.append(top, line, bottom);
        this.dom.onscreen.append(b);
        return b;
    }


    processSocket(e) {
        e.data.arrayBuffer().then(ab => {
            const view = socket.p(ab);

            switch (view.getUint8(0)) {
                case 67:
                    this.finishPlay(view);
                    break;
                case 62:
                    this.startPlay(view);
                    break;
                case 8: {
                    const x = view.getInt16(1) / 16384;
                    const y = view.getInt16(3) / 16384;
                    const score = view.getInt8(5);
                    const time = view.getFloat32(6);
                    const id = view.getUint8(10);
                    this.stats.ammo = view.getUint8(11);

                    const now = this.getNow();
                    if (id !== 0xff) {
                        const t = this.targets.find(e => e.id === id);
                        if (t) {
                            t.timeEnd = now + 0.15;
                            t.shoot = true;
                            t.dom?.classList.add('hide-info');
                        }

                        this.median.sum += time;
                        this.median.count++;
                    }

                    this.addResult(x, y, score, time);

                    this.updateScores();

                    if (score > 0) {
                        new Promise(async () => {
                            const a = this.sound.success.cloneNode();
                            await a.play();
                            a.remove();
                        });
                    }

                    const gunPointMatrix = this.gun.node.armature.named['dulo'].worldTransform;
                    const gunPoint = new Vector(gunPointMatrix.m30, gunPointMatrix.m31, gunPointMatrix.m32);
                    const gun = Matrix.mulVector4(this.projectView, gunPoint);
                    gun.x = (gun.x / gun.w) * 0.5 + 0.5;
                    gun.y = ((gun.y / gun.w) * 0.5 + 0.5) * this.height / this.width;

                    const dx = x - gun.x;
                    const dy = y - gun.y;

                    this.traces.push({ x: gun.x + dx * 0.5, y: gun.y + dy * 0.5, length: Math.sqrt(dx * dx + dy * dy), rotation: Math.atan2(dy, dx), start: this.getNow() });
                }
                    break;
                case 68: {
                    const now = this.getNow();
                    const newTargetsLength = view.getUint8(1);
                    const removedLength = view.getUint8(2);

                    let ptr = 3;

                    for (let i = 0; i < newTargetsLength; i++) {
                        this.targets.push({
                            id: view.getUint8(ptr++),
                            type: view.getUint8(ptr++),
                            serverStart: view.getFloat64(ptr),
                            size: view.getFloat32(ptr + 8),
                            times: [0, 2, 4, 6].map(offset => view.getUint16(ptr + 12 + offset) / 1000),
                            lifeTime: view.getFloat32(ptr + 20),
                            params: {
                                a: view.getUint8(ptr + 24) / 100,
                                b: view.getUint8(ptr + 25) / 100,
                                c: view.getUint8(ptr + 26) / 100,
                                g: view.getUint8(ptr + 27) / 100,
                            },
                            dom: this.addInfo(0.5, 0.1),
                            timeStart: now,
                            timeEnd: 0,
                        });
                        ptr += 28;
                    }

                    for (let i = 0; i < removedLength; i++) {
                        this.removed.push({
                            id: view.getUint8(ptr++),
                            shoot: view.getUint8(ptr++),
                            score: view.getInt8(ptr++),
                        })
                    }

                    this.stats.success = view.getUint8(ptr++);
                    this.stats.miss = view.getUint8(ptr++);
                    this.stats.milk = view.getUint8(ptr++);
                    this.stats.score = view.getInt8(ptr++);
                    this.stats.ammo = view.getInt8(ptr++);
                    this.stats.targets = view.getInt8(ptr++);

                    if (newTargetsLength > 0) {
                        new Promise(async () => {
                            const a = this.sound.new.cloneNode();
                            await a.play();
                            a.remove();
                        });
                    }

                    if (this.removed.some(e => e.score < 0)) {
                        new Promise(async () => {
                            // const a = this.sound.miss.cloneNode();
                            // await a.play();
                            // a.remove();
                        });
                    }

                    this.updateScores();
                }
                    break;
            }
        });
    }

    finish() {

    }

    startPlay(view) {
        this.playing = true;
        this.timeEnd = 0;
        this.serverStart = view.getFloat64(1);
        // this.sound.background.currentTime = 0;
        // this.sound.background.play();
        this.gun.node.armature.startAction(this.gun.actions['Begin']);

        this.targets = [];
        this.removed = [];
        this.median.count = 0;
        this.median.sum = 0;

        this.stats.milk = 0;
        this.stats.miss = 0;
        this.stats.success = 0;
        this.stats.left = 20;
        this.stats.ammo = 20;

        this.timeStart = this.getNow();

        this.updateScores();
        this.dom.holder.classList.add('no-cursor');
        this.dom.onscreen.innerHTML = '';
    }

    finishPlay(view) {
        const time = this.getNow();
        this.timeEnd = time;
        this.playing = false;
        this.targets.forEach(e => {
            if (!e.timeEnd)
                e.timeEnd = time
        });
        this.dom.holder.classList.remove('no-cursor');
        const win = view.getUint32(1);
        const score = view.getInt8(5);
        if (this.resultCallback)
            this.resultCallback({ win, score });
    }

    updateScores() {
        this.dom.targets.innerText = `${this.stats.targets}`;
        this.dom.reaction.innerText = this.median.count === 0 ? '0.00' : `${(Math.round(this.median.sum / this.median.count * 100) / 100).toFixed(2)}`;
        this.dom.score.innerText = `${this.stats.score}`;
        this.dom.success.innerText = `${this.stats.success}`;
        this.dom.miss.innerText = `${this.stats.miss}`;
        this.dom.ammo.innerText = `${this.stats.ammo}`;
    }

    mouseMove(e) {
        const cs = this.canvas.getBoundingClientRect();
        this.mouse.x = (e.pageX - cs.x) / cs.width;
        this.mouse.y = this.aspectRatio - (e.pageY - cs.y) / cs.width;
    }

    mouseClick(e) {
        if (!this.playing || e.button !== 0)
            return;

        this.gun.node.armature.startAction(this.gun.actions['Shoot']);

        const cs = this.canvas.getBoundingClientRect();
        const x = (e.pageX - cs.x) / cs.width;
        const y = this.aspectRatio - (e.pageY - cs.y) / cs.width;

        this.mouse.x = x;
        this.mouse.y = y;

        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        const now = this.getNow();
        this.moveCursor(now, this.mouse.dx, this.mouse.dy);

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        const a = new ArrayBuffer(14);
        const b = new DataView(a);
        b.setInt16(0, Math.round(x * 16384));
        b.setInt16(2, Math.round(y * 16384));
        b.setFloat64(4, this.getServerTime(now));
        b.setInt16(12, Math.round(this.aim.size * 16384));
        socket.send(8, a);

        new Promise(async () => {
            const a = this.sound.shoot.cloneNode();
            a.volume = 0.6;
            await a.play();
            a.remove();
        });
    }

    moveCursor(time, dx, dy) {
        // if (!this.playing)
        //     return;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const move = dist > 0;

        if (dist > 0.05) {
            this.aim.size = Math.max(this.aim.size, 0.8);
            this.aim.move = true;
            this.aim.prevMove = true;
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
        }

        if (move && !this.aim.move || !move && this.aim.move) {
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
            this.aim.move = move;
        }
        else {
            const dt = (time - this.aim.prevTime) * ((time - this.aim.triggerTime > .15 || this.aim.move) ? 1 : 0);
            // this.aim.size = 0*Math.min(1, Math.max(0, this.aim.size + dt * (this.aim.move ? dist * 500 : -5)));
            this.aim.size = Math.min(1, Math.max(0, this.aim.size + dt / (this.aim.prevMove ? .2 : .2) * (this.aim.move ? 1 : -1)));
            this.aim.prevTime = time;
        }

        this.aim.prevMove = this.aim.move;
    }


    drawQuad(texCrop, x, y, z, w, h, opaque = 1) {
        const gl = this.gl;
        this.shader.bindUniforms(x, y, z, w, h, texCrop, opaque);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    getNow() {
        return performance.now() / 1000 - this.start;
    }

    getServerTime(time) {
        return time - this.timeStart + this.serverStart;
    }

    getTargetPos(percent, params) {
        const { a, b, c, g } = params;
        const x = Math.max(0, Math.min(1, percent));
        const affine = (2.9 - 2.6 * x) * g * x + (Math.sin(20 * a * x + 3 * b) * .1 + .1) * c;
        return { x: percent, y: this.aspectRatio * (0.1 + affine * 0.8) };
    }

    drawTargets(time) {
        const serverTime = this.getServerTime(time);
        this.targets.forEach(target => {
            const atlas = target.shoot ? this.atlas.elements.shoot : this.atlas.elements.target;
            const sizeScale = Math.min(1, Math.max(0, 1. - .5 * (time - target.timeStart) / target.lifeTime));
            const size = 2 * target.size * sizeScale;
            const { x, y } = this.getTargetPos((serverTime - target.serverStart) / target.lifeTime, target.params);
            this.drawQuad(atlas, x, y, 0, size, size * atlas.ar)
        });
    }

    drawGun(time) {
        if (!this.gun.node)
            return
        const gl = this.gl;
        this.projectView.projection(this.width, this.height, 0.1, 20);

        const x = this.mouse.x * 2 - 1;
        const y = this.mouse.y;
        const node = this.gun.node;
        node.pos.x = 0;
        node.pos.y = -0.5 + y * 0.1;
        const horQ = Quaternion.fromAxisAngle(1, 0, 0, 1.5 - y * 0.5);
        const verQ = Quaternion.fromAxisAngle(0, 1, 0, x);
        node.rot = Quaternion.mul(horQ, verQ);

        this.gun.node.update(this.gun.identityMatrix);
        gl.enable(gl.DEPTH_TEST);
        gl.enable(gl.CULL_FACE);
        gl.cullFace(gl.BACK);
        this.gun.shader.bind(this.projectView.toArray(), this.gun.node.armature.toArray(), time);
        this.gun.node.draw(this.gl, this.gun.shader, this.gun.textures);
        gl.disable(gl.CULL_FACE);
        gl.disable(gl.DEPTH_TEST);
    }

    drawTraces(time) {
        const gl = this.gl;
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        this.traceShader.bind(this.aspectRatio);
        this.quadMesh.prepareDraw(this.traceShader.attributions.position);

        for (let i = 0; i < this.traces.length; i++) {
            const trace = this.traces[i];
            const percent = Math.min(1, (time - trace.start) / .2);
            this.traceShader.bindUniforms(trace.x, trace.y, trace.length + Math.min(.03, trace.length * .02), trace.rotation, percent);

            if (percent > 0.99)
                this.traces.splice(i, 1);

            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        }
    }

    drawAim(time) {
        const size = (this.aim.size * 0.6 + 0.4) * this.aim.maxSize;
        const sideSize = 0.25 * this.aim.maxSize;
        const sideDelta = size * (.7 + this.aim.size * .2);
        const visibility = Math.min(1, Math.max(0, this.timeEnd ? 1 - (time - this.timeEnd) * 2 : 1.));

        const gl = this.gl;
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

        this.aim.shader.bind(this.aspectRatio);
        this.quadMesh.prepareDraw(this.aim.shader.attributions.position);

        this.aim.shader.bindUniforms(this.mouse.x, this.mouse.y / this.aspectRatio, this.aim.maxSize, this.aim.size);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }


    updateInfo(time) {
        const serverTime = this.getServerTime(time);
        this.targets.forEach(e => {
            const timeDelta = serverTime - e.serverStart;
            let score = 5;
            for (let i = 0; i < 4; i++) {
                if (timeDelta < e.times[i]) {
                    score = i + 1;
                    break;
                }
            }
            const { x, y } = this.getTargetPos((serverTime - e.serverStart) / e.lifeTime, e.params);
            e.dom.style.left = `${Math.round(this.canvasSize.width * (x - 0.07))}px`;
            e.dom.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.01))}px`;
            e.dom.childNodes[0].innerText = `${timeDelta.toFixed(1)} м/с`;
            e.dom.childNodes[2].innerText = `+${score} points`;
        });
    }


    draw() {
        if (this.mouse.__prev === null)
            this.mouse.__prev = { x: this.mouse.x, y: this.mouse.y };
        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        const time = this.getNow();
        this.moveCursor(this.getNow(), this.mouse.dx, this.mouse.dy);


        this.targets.forEach(e => {
            const rm = this.removed.find(u => e.id === u.id);
            if (rm && !e.timeEnd) {
                e.timeEnd = time;
                e.shoot = rm.shoot;

                if (!rm.shoot)
                    this.addResult(e.x, e.y, rm.score);
            }
        });
        this.removed = [];

        for (let i = this.targets.length - 1; i >= 0; i--) {
            if (this.targets[i].timeEnd && time - this.targets[i].timeEnd > 1) {
                this.targets[i].dom?.remove();
                this.targets.splice(i, 1);
            }
        }

        const gl = this.gl;
        gl.viewport(0, 0, this.width, this.height);
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.enable(gl.BLEND);
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.blendEquation(gl.FUNC_ADD);


        this.quadMesh.prepareDraw(this.shader.attributions.position);

        // const P = this.playing ? Math.min(1, time - this.timeStart) : Math.max(0, this.timeEnd + 1. - time);
        // this.sound.background.volume = P * 0.6;

        this.shader.bind(this.aspectRatio, 0);
        this.atlas.bind(0);

        this.drawQuad(this.atlas.elements.bg1, 0.5, this.aspectRatio * .5, 0, 1, this.aspectRatio, 1);

        this.drawTargets(time);

        if (!this.timeEnd || this.timeEnd + 1. > time) {
            this.updateInfo(time);
            this.shader.bind(this.aspectRatio, 0);
            this.drawTraces(time);
            this.drawGun(time);
            this.drawAim(time);
        }

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        this.prevTime = time;
        requestAnimationFrame(__caller);
    }

}


module.exports = ShootGame3;