const Texture = require("@/game/Texture");

class Atlas extends Texture {
    constructor(gl) {
        super(gl, 'tir2/atlas.png');

        const size = 2048;

        // left, top, right, bottom, footY, footX, headY
        this.elements = {
            scene: {
                gun: [0, 0, 478, 490],
                aim: [768, 1013, 1020, 1197],
                side: [1395, 1315, 1495, 1620],
            },
            ufo: [
                [510, 30, 945, 235],
                [500, 235, 930, 485],
                [995, 20, 1415, 400],
                [1465, 28, 1865, 310],
            ],
            ufoBurst: [
                [44, 505, 675, 930],
                [510, 500, 910, 930],
                [990, 400, 1410, 830],
                [1465, 310, 1870, 730],
            ],
            planet: [
                [21, 968, 481, 1327],
                [1169, 835, 1569, 1210],
                [1630, 775, 2015, 1165],
                [10, 1425, 370, 1800],
                [430, 1271, 781, 1670],
                [860, 1275, 1250, 1655],
                [1630, 1215, 1985, 1570],
                [440, 1715, 755, 2040],
                [850, 1715, 1185, 2040],
                [1230, 1720, 1560, 2035],
                [1610, 1635, 2025, 2040]
            ]
        };

        for (const e of Object.keys(this.elements)) {
            if (this.elements[e] instanceof Array && typeof this.elements[e][0] === 'number') {
                this.elements[e] = this.__processArray(this.elements[e], size);
            }
            else {
                for (const u of Object.keys(this.elements[e]))
                    this.elements[e][u] = this.__processArray(this.elements[e][u], size);
            }
        }
    }

    __processArray(arr, size) {
        const cp = arr.map(e => e / size);
        const w = cp[2] - cp[0], h = cp[3] - cp[1];
        return { x: cp[0], y: cp[1], w, h, ar: h / w };
    }
}

module.exports = Atlas;