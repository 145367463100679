const Shader = require("@/game/Shader");

class AimShader extends Shader {
    __getShaders() {
        return {
            vert:
            // language=Glsl
                'precision highp float;attribute vec2 f;uniform vec4 a;uniform float c;varying vec2 m;' +
                'void main(){m=f*2.-1.;gl_Position=vec4(a.xy*2.-vec2(a.z,a.z*c)+vec2(a.z,a.z*c)*(m*.5+.5)*2.-1.,0,1);}',
            frag:
            // language=Glsl
                'precision mediump float;varying vec2 m;uniform vec2 h;const vec4 p=vec4(.15,.03,.68,.02);void main(){vec2 a=abs(m);' +
                'vec2 d=abs(vec2(max(a.x,a.y)-.4-h.y*.4,min(a.x,a.y)))-vec2(p.x,p.y);' +
                'gl_FragColor=vec4(vec3(1.,.05,.15),smoothstep(p.w,0.,max(d.x,d.y)));}'
        }
    }

    bind(ar) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
    }

    __initUniforms() {

        // aye
        return {
            params: this.gl.getUniformLocation(this.program, 'a'),
            ar: this.gl.getUniformLocation(this.program, 'c'),
            sizes: this.gl.getUniformLocation(this.program, 'h'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'f')
        }
    }

    bindUniforms(x, y, maxS, s, r) {
        const gl = this.gl;
        gl.uniform4f(this.uniforms.params, x, y, maxS, 0);
        gl.uniform2f(this.uniforms.sizes, maxS, s);
    }
}

module.exports = AimShader;