const Shader = require("@/game/Shader");

class BackgroundShader extends Shader {
    __getShaders() {
        return {
            vert:
                `precision highp float;
                 attribute vec2 a;
                 varying vec2 uv;
                 void main() {
                     uv = a;
                     gl_Position = vec4(a * 2. - 1., 0., 1.);
                 }`,
            frag:
                `precision highp float;
                 uniform float time;
                 uniform float radar;
                 uniform vec2 mouse;
                 uniform vec3 clicks[6];
                 uniform vec4 items[8];
                 uniform float items2[8];
                 uniform float itemsCount;
                 uniform vec2 r;
                 uniform sampler2D I;
                 varying vec2 uv;
                 const float pi = 3.1415926536;
                 mat2 rot = mat2(.6,.8,-.8,.6);
                 vec3 fbm(vec2 p) {
                    vec3 a = texture2D(I, p).rgb * .5;p=rot*p*2.01;
                    a += texture2D(I, p).rgb * .25;p=rot*p*2.03;
                    a += texture2D(I, p).rgb * .125;p=rot*p*1.99;
                    return a;
                 }
                 void main() {
                     vec2 ar = vec2(1., r.y / r.x);
                     vec2 p = (uv * 2. - 1.) * ar;
                     float px = 1. / r.x;
                     vec2 sum = vec2(0.);

                     for (float i = 0.; i < 6.; i += 1.) {
                         vec2 m = clicks[int(i)].xy * 2. - ar;
                         float len = length(p - m);
                         float delta = (time - clicks[int(i)].z) * 0.7;
                         float maxTime = 0.7;
                         float scale = 20.;
                         float wave = sin((delta - len) * scale) * step(len, delta) * exp(-len * 3.) * exp(-(delta - len) * 3.);
                         vec2 direction = normalize(m - p);
                         sum += direction * wave;
                     }

                     vec2 q = p * 20. + sum * 0.5;
                     vec2 Q = p + sum * 0.025;
                     vec2 g2 = abs(fract(q) * 2. - 1.) / 40.;
                     float R = length(p);
                     vec3 grid = max(smoothstep(3. * px, 2. * px, g2.x), smoothstep(3. * px, 2. * px, g2.y)) * vec3(.03, .42, .24) * smoothstep(1.2, 0.3, R);
                     vec3 radial = vec3(.02, .33, .19) * smoothstep(1.0, 0.3, R);
                     vec3 bgColor = max(grid, radial);

                     float td = mod(time * .7, 2. * pi);
                     vec2 angles = vec2(atan(p.y, p.x));
                     angles.y += pi;
                     angles = angles - td;
                     angles = mod(angles + pi, 2. * pi) - pi;
                     angles = smoothstep(vec2(.2), vec2(.05), abs(angles));
                     vec3 rColor = max((angles.x + angles.y) * vec3(0.01, .2, 0.01), smoothstep(0.01, 0., R) * vec3(0.3, .5, .5));
                     rColor += vec3(.01, .05, .05) * smoothstep(.01, 0., abs(R - fract(time * .4) * 2.));
                     bgColor += radar * rColor;

                     vec4 itemC = vec4(0.);
                     for (float i = 0.; i < 8.; i += 1.) {
                         if (itemsCount <= i) break;
                         vec4 item = items[int(i)];
                         float item2 = items2[int(i)];
                         vec2 pos = item.xy * 2. - ar;
                         float size = item.z * 2.;
                         float maxDeltaBlur = 0.01;
                         vec2 sizeCl = vec2(size + maxDeltaBlur, size - maxDeltaBlur) * item2;
                         float type = floor(item.w / 3.);
                         float colorId = mod(item.w, 3.);
                         vec3 color = vec3(.2, .9, .1);
                         if (colorId == 1.) color = vec3(.9, .3, .1);
                         else if (colorId == 2.) color = vec3(.8, .9, .1);

                         if (type == 0.) {
                             float st = smoothstep(sizeCl.x, sizeCl.y, length(pos - Q));
                             itemC = vec4(mix(itemC.rgb, color, st), mix(itemC.a, st, st));
                         } else if (type == 1.) {
                             vec2 dd = abs(pos - Q);
                             float st = smoothstep(sizeCl.x, sizeCl.y, dd.x) * smoothstep(sizeCl.x, sizeCl.y, dd.y);
                             itemC = vec4(mix(itemC.rgb, color, st), mix(itemC.a, st, st));
                         } else if (type == 2.) {
                             vec2 dd = abs(pos - Q) * 0.707;
                             float st = smoothstep(sizeCl.x, sizeCl.y, dd.x + dd.y);
                             itemC = vec4(mix(itemC.rgb, color, st), mix(itemC.a, st, st));
                         }
                     }
                     
                     float ct = time * .5;
                     float t1 = floor(ct), t2 = ct - t1;
                     vec3 f1 = fbm(Q * .1 + t1 * .1), f2 = fbm(Q * .1 + t1 * .1 + .1);
                     vec3 f = mix(f1, f2, (3. * t2 - 2. * t2 * t2) * t2);
                     bgColor += f * .3;
                     
                     vec3 screen = mix(bgColor, itemC.rgb, itemC.a);

                     gl_FragColor = vec4(mix(screen, vec3(.2, .8, .8), smoothstep(px * 9., 0., length(mouse * 2. - ar - p))), 1.0);
                 }`
        }
    }

    bind(time, radar, mouse, res, items) {
        const m = [], n = [], n2 = [];
        mouse.clicks?.forEach(e => m.push(e.x, e.y, e.time));

        for (let i = 0; i < Math.min(items.length, 8); i++) {
            const e = items[i];
            n.push(e.x || 0, e.y || 0, e.size || 0, e.type || 0);
            const percent = Math.min(1, Math.max(0, (time - e.timeStart) / e.lifeTime));
            let sz = (e.timeEnd === 0 ? Math.sin(2 * Math.PI * Math.pow(percent, .4) - Math.PI * .5) * .5 + .5 : 0) || 0;
            n2.push(Math.max(sz, 0.001));
        }

        super.bind();
        this.gl.uniform1f(this.uniforms.time, time);
        this.gl.uniform1f(this.uniforms.radar, radar);
        this.gl.uniform2f(this.uniforms.mouse, mouse.x, mouse.y);
        if (m.length)
            this.gl.uniform3fv(this.uniforms.clicks, m);
        if (n.length) {
            this.gl.uniform4fv(this.uniforms.items, n);
            this.gl.uniform1fv(this.uniforms.items2, n2);
        }
        this.gl.uniform1f(this.uniforms.itemsCount, items.length);
        this.gl.uniform2f(this.uniforms.r, res.w, res.h);
        this.gl.uniform1i(this.uniforms.I, 0);
    }

    __initUniforms() {
        return {
            time: this.gl.getUniformLocation(this.program, 'time'),
            radar: this.gl.getUniformLocation(this.program, 'radar'),
            mouse: this.gl.getUniformLocation(this.program, 'mouse'),
            clicks: this.gl.getUniformLocation(this.program, 'clicks'),
            items: this.gl.getUniformLocation(this.program, 'items'),
            items2: this.gl.getUniformLocation(this.program, 'items2'),
            itemsCount: this.gl.getUniformLocation(this.program, 'itemsCount'),
            I: this.gl.getUniformLocation(this.program, 'I'),
            r: this.gl.getUniformLocation(this.program, 'r'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'a'),
        }
    }

    bindUniforms() {

    }
}

module.exports = BackgroundShader;