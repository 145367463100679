<template>
    <div>
        <div style="user-select: none" class="game-2">
            <div style="width: 100%;height: 3vw;background: #1F2F43;display: block">
                <input @click="start" type="button" value="start">
            </div>
            <div id="shootGame2-holder">
                <div style="position: relative;width: 0;height: 0" id="game-2-onscreen" class="onscreen"/>
                <div style="position: relative;width: 100%;height: 0;">
                    <div style="position: absolute;width: 100%;display: flex;justify-content:center;height: 3vw">
                        <div style="height: 3vw;flex: 0 0 7vw;position:relative;">
                            <img style="display: block;width: 100%;height: 100%;position: absolute;" src="/tir2/top.png">
                            <div style="position: absolute;width: 80%;height: 80%;top:10%;left: 10%;display: flex;align-items: center;justify-content: center">
                                <img style="display: block;height: 1.3vw;" src="/tir2/target.png">
                                <div style="color: #ffffff;font-size: 1.5vw;padding-left: 0.5vw;font-weight: 600" id="game-2-targets"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <canvas id="shootGame2" style="margin: 0;padding: 0;flex: 0 0"/>
            </div>
            <div class="bottom-game">
                <div class="left">
                    <img class="bg-img" src="/tir2/b-left.png">
                    <div class="content">
                        <div style="display: flex">
                            <div style="color: #ffffff; font-size: 1vw; align-self: center;">Ammo:</div>
                            <div style="color: #00E0FF; font-size: 3vw;font-weight: 600;padding: 0 .3vw" id="game-2-ammo">20</div>
                            <div style="color: #226B98; font-size: 1.5vw;align-self: end;font-weight: 500">/ 20</div>
                        </div>
                        <div style="display: flex;align-items: center">
                            <img style="display: block;width: 4vw;" src="/tir2/success.png">
                            <div style="font-weight: 600;color: #ffffff;font-size: 2vw;padding-left: 1vw;width: 4vw" id="game-2-success">0</div>
                        </div>
                        <div style="display:flex;align-items: center;color: #ffffff">
                            <div style="font-size: .7vw;width: 6vw">Среднее время<br>тарелки на поле</div>
                            <div style="white-space: nowrap;font-size: 2vw;font-weight: 600">
                                <span id="game-2-reaction">0.00</span> м/с
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center">
                    <img class="bg-img" src="/tir2/b-center.png">
                    <div class="content">
                        <div style="display: flex;color: #ffffff;align-items: center">
                            <img style="display: block;width: 4vw;" src="/tir2/miss.png">
                            <div style="display: flex;flex-direction: column;padding: 0 .5vw;align-items: center">
                                <div style="text-transform: uppercase;font-size: 1vw">points</div>
                                <div style="font-size: 2vw;font-weight: 600">-5</div>
                            </div>
                            <div style="color: #EB200A;font-size: 1.5vw;font-weight: 600;white-space: nowrap">x <span id="game-2-miss">0</span></div>
                        </div>
                        <div style="display: flex;color: #ffffff;align-items: center">
                            <img style="display: block;width: 3vw;height: 3vw" src="/tir2/milk.png">
                            <div style="display: flex;flex-direction: column;padding: 0 .5vw;align-items: center">
                                <div style="text-transform: uppercase;font-size: 1vw">points</div>
                                <div style="font-size: 2vw;font-weight: 600">-3</div>
                            </div>
                            <div style="color: #EB200A;font-size: 1.5vw;font-weight: 600;white-space: nowrap">x <span id="game-2-milk">0</span></div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img class="bg-img" src="/tir2/b-right.png">
                    <div class="content">
                        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
                            <div style="color: #ffffff;text-transform: uppercase;font-size: 1vw">Points</div>
                            <div style="color: #00E0FF;font-size: 4vw;font-weight: 600" id="game-2-score">0</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Shop ref="shop"/>
    </div>
</template>

<script>
import ShootGame2 from "@/game/shootGame2";
import Shop from "@/Shop.vue";

export default {
    components: { Shop },
    data: () => ({
        game: null,
    }),
    methods: {
        start() {
            this.game.wantStart();
        },
    },
    mounted() {
        this.game = new ShootGame2(this.$refs.shop.onResult);
    },
    beforeUnmount() {
        this.game.finish();
    }
}
</script>

<style lang="less">

@keyframes shootGame2-info-hide {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-1vw);
        opacity: 0;
    }
}

.game-2 {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', serif;

    #shootGame2-holder {
        display: flex;
        flex-direction: column;

        .onscreen {
            .block {
                display: flex;
                width: 4vw;
                height: 4vw;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                text-align: center;
                animation-duration: 900ms;
                animation-name: game-4-score;
                animation-fill-mode: forwards;

                .time {
                    font-size: 0.8vw;
                    color: #eeeeee;
                }

                .text {
                    font-size: 2vw;
                    color: #ffffff;
                }

                .red {
                    color: #FF0C25;
                }

                .green {
                    color: #13D93E;
                }
            }

            .hide-info {
                animation-duration: .3s;
                animation-name: shootGame2-info-hide;
                animation-fill-mode: forwards;
            }

            .info {
                position: absolute;
                display: flex;
                flex-direction: column;
                width: 5vw;
                font-size: .8vw;
                color: #13D93E;

                .line {
                    height: .1vw;
                    width: 100%;
                    position: relative;
                    background: #13D93E;
                    margin: .1vw 0;

                    .dot {
                        position: absolute;
                        height: .6vw;
                        width: .6vw;
                        right: -.2vw;
                        top: -.25vw;
                        background: #13D93E;
                        border-radius: .3vw
                    }
                }
            }
        }
    }

    .bottom-game {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 9vw;
        background: #1F2F43;
        display: flex;
        justify-content: space-around;
        position: relative;

        .bg-img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .left {
            position: relative;
            height: 9vw;
            width: 45vw;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 79%;
                height: 50%;
                left: 12%;
                top: 38%;
            }
        }

        .center {
            position: relative;
            height: 9vw;
            width: 35vw;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                width: 79%;
                height: 50%;
                left: 9%;
                top: 38%;
            }
        }

        .right {
            position: relative;
            height: 9vw;
            width: 15vw;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                width: 85%;
                height: 68%;
                left: 6%;
                top: 23%;
            }
        }
    }
}
</style>