<template>
    <div style="font-family: sans-serif">
        <div style="width: 100%;height: 2vw;background: #212121;color: #FFFFFF;font-size: 1vw;line-height: 2vw;padding-left: 4vw;box-sizing: border-box">Призы</div>
        <div style="width: 100%; background: #FFFFFF; display: flex; flex-wrap: wrap;justify-content: space-around">
            <div v-for="i in scores" style="flex: 0 0 19%;width: 19%">
                <img :src="`/shop/${i}.jpg`" alt="" style="display: block;width: 100%; height: 18vw;user-select: none" draggable="false" @contextmenu.prevent="() => {}">
            </div>
        </div>

        <div class="over-dialog" v-if="show">
            <div class="dialog" :style="`background-image: url(/shop/win-${minNearestScore}.jpg)`">
                <div style="font-size: 3.8vw;color: #eac029;text-align: center;">Поздравляем!</div>
                <div style="font-size: 2vw;color: #18eab4;margin: 2vw 0;text-align: center">Вы набрали {{ score }} {{ wordForm }}</div>
                <div style="font-size: 3.5vw;color: #af6937;text-align: center">Ваш приз</div>
                <div style="font-size: 7.5vw;color: #cdc719;text-align: center">{{ win }}</div>
                <div style="font-size: 4.5vw;color: #af6937;text-align: center">рублей</div>
                <div class="close" @click="show = false">Закрыть</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        scores: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
        score: 0,
        minNearestScore: 0,
        win: 0,
        wordForm: '',
        show: false,
    }),
    methods: {
        onResult(data) {
            this.win = data.win || 0;
            this.score = data.score || 0;

            if (this.score > 20 && this.score % 10 === 1)
                this.wordForm = 'очко';
            else if (this.score > 20 && this.score % 10 >= 2 && this.score % 10 <= 4)
                this.wordForm = 'очка';
            else
                this.wordForm = 'очков';

            let mf = 1000;
            const scoreBg = [0, 60, 75, 90];
            for (let i = scoreBg.length - 1; i >= 0; i--) {
                if (this.score < mf)
                    mf = scoreBg[i];
            }
            this.minNearestScore = mf;

            this.show = true;
        }
    }
}
</script>
<style scoped>
.over-dialog {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.52);
}

.dialog {
    box-shadow: 0 0 15px #0004;
    width: 30vw;
    border-radius: 1vw;
    background-color: #5a7368;
    padding: 2vw;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
}

.close {
    color: #ffffff;
    background: #212121;
    padding: 1vw;
    border-radius: .5vw;
    font-size: 1vw;
    margin-top: 2vw;
    text-align: center;
    cursor: pointer;
}
</style>