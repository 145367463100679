class Texture {
    constructor(gl, path, mipmaps = false, repeat = false) {
        this.gl = gl;

        this.id = gl.createTexture();

        const image = new Image();
        image.onload = () => {
            const mipmaps = image.width.toString(2).lastIndexOf('1') + image.height.toString(2).lastIndexOf('1') === 0
            gl.bindTexture(gl.TEXTURE_2D, this.id);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, repeat ? gl.REPEAT : gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, repeat ? gl.REPEAT : gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, mipmaps ? gl.LINEAR_MIPMAP_LINEAR : gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            if (mipmaps)
                gl.generateMipmap(gl.TEXTURE_2D);
            gl.bindTexture(gl.TEXTURE_2D, null);
        }
        image.src = `/${path}`;
    }

    bind(position) {
        const gl = this.gl;
        switch (position) {
            case 0:
                gl.activeTexture(gl.TEXTURE0);
                break;
            case 1:
                gl.activeTexture(gl.TEXTURE1);
                break;
            case 2:
                gl.activeTexture(gl.TEXTURE2);
                break;
            default:
                throw new Error(`invalid tex pos ${position}. valid: 0, 1, 2`);
        }
        gl.bindTexture(this.gl.TEXTURE_2D, this.id);
    }

    clear() {
        this.gl.deleteTexture(this.id);
        this.id = 0;
    }
}

module.exports = Texture;