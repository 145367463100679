const Shader = require("@/game/Shader");

class CenterShader extends Shader {
    __getShaders() {
        return {
            // language=Glsl
            vert:
                'precision highp float;attribute vec2 a;varying vec2 m;uniform vec4 b,c;uniform float e,f;' +
                'void main(){m=vec2(a.x,1.-a.y)*c.zw+c.xy,gl_Position=vec4((a*b.zw+b.xy-b.zw*0.5)*vec2(1,e)*2.-1.,f,1);}',
            // language=Glsl
            frag:
                'precision mediump float;varying vec2 m;uniform sampler2D i;uniform float q;' +
                'void main(){vec4 a=texture2D(i,m.xy).rgba;gl_FragColor=vec4(a.rgb,a.a*q);}'
        }
    }

    bind(ar, img) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
        this.gl.uniform1i(this.uniforms.image, img);
    }

    __initUniforms() {
        return {
            screenCrop: this.gl.getUniformLocation(this.program, 'b'),
            textureCrop: this.gl.getUniformLocation(this.program, 'c'),
            ar: this.gl.getUniformLocation(this.program, 'e'),
            z: this.gl.getUniformLocation(this.program, 'f'),
            image: this.gl.getUniformLocation(this.program, 'i'),
            opaque: this.gl.getUniformLocation(this.program, 'q'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'a')
        }
    }

    bindUniforms(x, y, z, w, h, textureCrop, opaque) {
        const gl = this.gl;
        gl.uniform1f(this.uniforms.z, -z / 10.);
        gl.uniform4f(this.uniforms.screenCrop, x, y, w, h);
        gl.uniform4f(this.uniforms.textureCrop, textureCrop.x, textureCrop.y, textureCrop.w, textureCrop.h);
        gl.uniform1f(this.uniforms.opaque, opaque);
    }
}


module.exports = CenterShader;