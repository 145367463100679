class Vector {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    static interpolate(a, b, w) {
        return new Vector(a.x + (b.x - a.x) * w, a.y + (b.y - a.y) * w, a.z + (b.z - a.z) * w);
    }
}

class Vector4 {
    constructor(x, y, z, w) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w;
    }
}

class Quaternion {
    constructor(w, x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w;
    }

    normalize() {
        const s = this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w;
        if (s === 0) {
            this.x = 0;
            this.y = 0;
            this.z = 0;
            this.w = 1;
        }
        else {
            const m = 1 / Math.sqrt(s);
            this.x *= m;
            this.y *= m;
            this.z *= m;
            this.w *= m;
        }
        return this;
    }

    static interpolate(a, b, blend) {
        const dot = a.w * b.w + a.x * b.x + a.y * b.y + a.z * b.z;
        const blendI = 1.0 - blend;

        const result = new Quaternion(0, 0, 0, 1);

        if (dot < 0) {
            result.w = blendI * a.w - blend * b.w;
            result.x = blendI * a.x - blend * b.x;
            result.y = blendI * a.y - blend * b.y;
            result.z = blendI * a.z - blend * b.z;
        }
        else {
            result.w = blendI * a.w + blend * b.w;
            result.x = blendI * a.x + blend * b.x;
            result.y = blendI * a.y + blend * b.y;
            result.z = blendI * a.z + blend * b.z;
        }

        return result.normalize();
    }

    static fromAxisAngle(x, y, z, a) {
        const factor = Math.sin(a / 2.0);
        return new Quaternion(Math.cos(a / 2.0), x * factor, y * factor, z * factor).normalize();
    }

    static mul(a, b) {
        return new Quaternion(
            a.w * b.w - a.x * b.x - a.y * b.y - a.z * b.z,
            a.w * b.x + a.x * b.w + a.y * b.z - a.z * b.y,
            a.w * b.y - a.x * b.z + a.y * b.w + a.z * b.x,
            a.w * b.z + a.x * b.y - a.y * b.x + a.z * b.w
        );
    }
}

class Matrix {
    constructor(arr) {
        this.identity();

        if (arr) {
            for (let i = 0; i < 16; i++)
                this[`m${Math.floor(i / 4)}${i % 4}`] = arr[i];
        }
    }

    toArray(arr = null) {
        arr ||= [];
        for (let i = 0; i < 16; i++)
            arr.push(this[`m${Math.floor(i / 4)}${i % 4}`]);
        return arr;
    }

    identity() {
        this.m00 = 1;
        this.m01 = 0;
        this.m02 = 0;
        this.m03 = 0;
        this.m10 = 0;
        this.m11 = 1;
        this.m12 = 0;
        this.m13 = 0;
        this.m20 = 0;
        this.m21 = 0;
        this.m22 = 1;
        this.m23 = 0;
        this.m30 = 0;
        this.m31 = 0;
        this.m32 = 0;
        this.m33 = 1;
    }

    projection(width, height, near, far) {
        this.m00 = 1;
        this.m01 = 0;
        this.m02 = 0;
        this.m03 = 0;

        this.m10 = 0;
        this.m11 = width / height;
        this.m12 = 0;
        this.m13 = 0;

        this.m20 = 0;
        this.m21 = 0;
        this.m22 = far / (near - far);
        this.m23 = -1;

        this.m30 = 0;
        this.m31 = 0;
        this.m32 = far * near / (near - far);
        this.m33 = 0;
    }

    static mul(a, b) {
        const res = new Matrix();

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
                let product = 0;
                for (let k = 0; k < 4; k++)
                    product += a[`m${k}${j}`] * b[`m${i}${k}`];
                res[`m${i}${j}`] = product;
            }
        }

        return res;
    }

    static mulVector4(m, v) {
        return new Vector4(
            m.m00 * v.x + m.m10 * v.y + m.m20 * v.z + m.m30,
            m.m01 * v.x + m.m11 * v.y + m.m21 * v.z + m.m31,
            m.m02 * v.x + m.m12 * v.y + m.m22 * v.z + m.m32,
            m.m03 * v.x + m.m13 * v.y + m.m23 * v.z + m.m33,
        );
    }

    static transformMatrix(pos, rot, scale) {
        const xy = rot.x * rot.y;
        const xz = rot.x * rot.z;
        const xw = rot.x * rot.w;
        const yz = rot.y * rot.z;
        const yw = rot.y * rot.w;
        const zw = rot.z * rot.w;
        const x2 = rot.x * rot.x;
        const y2 = rot.y * rot.y;
        const z2 = rot.z * rot.z;

        const rotatedMatrix = new Matrix();

        rotatedMatrix.m00 = 1 - 2 * (y2 + z2);
        rotatedMatrix.m01 = 2 * (xy - zw);
        rotatedMatrix.m02 = 2 * (xz + yw);
        rotatedMatrix.m03 = 0;

        rotatedMatrix.m10 = 2 * (xy + zw);
        rotatedMatrix.m11 = 1 - 2 * (x2 + z2);
        rotatedMatrix.m12 = 2 * (yz - xw);
        rotatedMatrix.m13 = 0;

        rotatedMatrix.m20 = 2 * (xz - yw);
        rotatedMatrix.m21 = 2 * (yz + xw);
        rotatedMatrix.m22 = 1 - 2 * (x2 + y2);
        rotatedMatrix.m23 = 0;

        rotatedMatrix.m30 = 0;
        rotatedMatrix.m31 = 0;
        rotatedMatrix.m32 = 0;
        rotatedMatrix.m33 = 1;

        const scaledMatrix = new Matrix([scale.x, 0, 0, 0, 0, scale.y, 0, 0, 0, 0, scale.z, 0, 0, 0, 0, 1]);

        const translatedMatrix = new Matrix([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, pos.x, pos.y, pos.z, 1]);

        return Matrix.mul(Matrix.mul(translatedMatrix, rotatedMatrix), scaledMatrix);
    }

    static toRotationMatrix(rot, pos) {
        const xy = rot.x * rot.y;
        const xz = rot.x * rot.z;
        const xw = rot.x * rot.w;
        const yz = rot.y * rot.z;
        const yw = rot.y * rot.w;
        const zw = rot.z * rot.w;
        const x2 = rot.x * rot.x;
        const y2 = rot.y * rot.y;
        const z2 = rot.z * rot.z;

        const matrix = new Matrix();

        matrix.m00 = 1 - 2 * (y2 + z2);
        matrix.m01 = 2 * (xy - zw);
        matrix.m02 = 2 * (xz + yw);
        matrix.m03 = 0;

        matrix.m10 = 2 * (xy + zw);
        matrix.m11 = 1 - 2 * (x2 + z2);
        matrix.m12 = 2 * (yz - xw);
        matrix.m13 = 0;

        matrix.m20 = 2 * (xz - yw);
        matrix.m21 = 2 * (yz + xw);
        matrix.m22 = 1 - 2 * (x2 + y2);
        matrix.m23 = 0;

        matrix.m30 = pos.x;
        matrix.m31 = pos.y;
        matrix.m32 = pos.z;
        matrix.m33 = 1;

        return matrix;
    }
}

class Joint {
    constructor(index, inverseBindTransform, name) {
        this.name = name;
        this.index = index;
        this.inverseBindTransform = inverseBindTransform;
        this.localTransform = new Matrix();
        this.worldTransform = new Matrix();
        this.invertedWorldTransform = new Matrix();

        this.rot = new Quaternion(0, 0, 0, 1);
        this.pos = new Vector(0, 0, 0);
        this.children = [];
    }

    setTransform(pos, rot) {
        this.pos = pos;
        this.rot = rot;
    }

    updateHierarchy(matrix) {
        this.localTransform = Matrix.toRotationMatrix(this.rot, this.pos);

        this.worldTransform = Matrix.mul(matrix, this.localTransform);

        for (const child of this.children)
            child.updateHierarchy(this.worldTransform);

        this.invertedWorldTransform = Matrix.mul(this.worldTransform, this.inverseBindTransform);
    }
}


class Armature {
    constructor(armature) {
        this.joints = [];
        this.jointsMap = {};
        this.named = {}
        for (const j of armature.bones) {
            const e = new Joint(j.index, j.matrix, j.name);
            this.joints.push(e);
            this.jointsMap[j.nameId] = e;
            this.named[j.name] = e;
        }

        const byNameIdBones = {};
        for (let i = 0; i < armature.bones.length; i++)
            byNameIdBones[armature.bones[i].nameId] = this.joints[i];

        for (let i = 0; i < armature.bones.length; i++) {
            for (const childId of armature.bones[i].children)
                this.joints[i].children.push(byNameIdBones[childId]);
        }

        this.rootJoin = byNameIdBones[armature.rootId];

        this.currentAction = null;
        this.timeStart = 0;
    }

    startAction(action) {
        this.currentAction = action;
        this.timeStart = performance.now() / 1000;
    }

    update(matrix) {
        if (!this.currentAction)
            return;

        const framesCount = this.currentAction.framesCount;
        const transforms = this.currentAction.transforms;

        const timeDelta = (performance.now() / 1000) - this.timeStart;
        const frameFloatId = timeDelta * 30;

        const frameId = Math.floor(frameFloatId);
        const nextFrameId = frameId + 1;

        const interpolation = frameFloatId - frameId;

        const realFrameId = Math.min(framesCount - 1, frameId);
        const realNextFrameId = Math.min(framesCount - 1, nextFrameId);

        for (const boneName in transforms) {
            const current = transforms[boneName][realFrameId];
            const next = transforms[boneName][realNextFrameId];
            this.jointsMap[boneName].setTransform(Vector.interpolate(current.pos, next.pos, interpolation), Quaternion.interpolate(current.rot, next.rot, interpolation));
        }

        this.rootJoin.updateHierarchy(matrix);
    }

    toArray() {
        const matrixes = [];
        for (const joint of this.joints)
            joint.invertedWorldTransform.toArray(matrixes);
        return matrixes;
    }
}

class Node {
    constructor(node, model) {
        const target = model.nodesMap[node];

        this.children = [];
        for (const child of target.children) {
            const tc = model.nodes[child];
            this.children.push(new Node(tc.name, model));
        }

        this.mesh = target.meshId !== null ? model.meshes[target.meshId] : null;
        this.gpu = {
            vertex: null,
            index: null,
            indexesCount: 0
        }
        this.armature = target.armatureId !== null ? new Armature(model.armatures[target.armatureId]) : null;

        this.localMatrix = new Matrix();
        this.globalMatrix = new Matrix();
        this.pos = new Vector(0, 0, 0);
        this.rot = new Quaternion(0, 0, 0, 1);
        this.scale = new Vector(1, 1, 1);
    }

    update(matrix) {
        this.localMatrix = Matrix.transformMatrix(this.pos, this.rot, this.scale);
        this.globalMatrix = Matrix.mul(matrix, this.localMatrix);
        if (this.armature)
            this.armature.update(this.globalMatrix);
    }

    storeMeshToGpu(gl) {
        this.gpu.vertex = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.gpu.vertex);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this.mesh.dataSlice, 0, this.mesh.dataSlice.byteLength / 4), gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        this.gpu.index = gl.createBuffer();
        this.gpu.indexesCount = Math.floor(this.mesh.indexSlice.byteLength / 2);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.gpu.index);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(this.mesh.indexSlice, 0, this.mesh.indexSlice.byteLength / 2), gl.STATIC_DRAW);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
    }

    draw(gl, shader, textures) {
        const p = shader.attributions;
        const u = shader.uniforms;


        gl.bindBuffer(gl.ARRAY_BUFFER, this.gpu.vertex);
        gl.vertexAttribPointer(p.pos, 3, gl.FLOAT, false, 32, 0);
        gl.vertexAttribPointer(p.normal, 4, gl.BYTE, true, 32, 12);
        gl.vertexAttribPointer(p.tangent, 4, gl.BYTE, true, 32, 16);
        gl.vertexAttribPointer(p.tex, 2, gl.SHORT, true, 32, 20);
        gl.vertexAttribPointer(p.weights, 4, gl.UNSIGNED_BYTE, true, 32, 24);
        gl.vertexAttribPointer(p.ids, 4, gl.UNSIGNED_BYTE, false, 32, 28);

        gl.enableVertexAttribArray(p.pos);
        gl.enableVertexAttribArray(p.normal);
        gl.enableVertexAttribArray(p.tangent);
        gl.enableVertexAttribArray(p.tex);
        gl.enableVertexAttribArray(p.weights);
        gl.enableVertexAttribArray(p.ids);

        textures.albedo.bind(0);
        textures.normal.bind(1);
        textures.phys.bind(2);

        gl.uniform1i(u.a, 0);
        gl.uniform1i(u.n, 1);
        gl.uniform1i(u.p, 2);

        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.gpu.index);
        gl.drawElements(gl.TRIANGLES, this.gpu.indexesCount, gl.UNSIGNED_SHORT, 0);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

        gl.disableVertexAttribArray(p.pos);
        gl.disableVertexAttribArray(p.normal);
        gl.disableVertexAttribArray(p.tangent);
        gl.disableVertexAttribArray(p.tex);
        gl.disableVertexAttribArray(p.weights);
        gl.disableVertexAttribArray(p.ids);

        gl.useProgram(null);
    }
}


class Model {
    constructor(blob) {
        const b = new DataView(blob, 0, blob.size);
        let pos = 0;

        function u8() {
            return b.getUint8(pos++);
        }

        function u16(id = false) {
            const r = b.getUint16(pos, true);
            pos += 2;
            return id && r === 65535 ? null : r;
        }

        function u32() {
            const r = b.getUint32(pos, true);
            pos += 4;
            return r;
        }

        function f32() {
            const r = b.getFloat32(pos, true);
            pos += 4;
            return r;
        }

        function v3() {
            return new Vector(f32(), f32(), f32());
        }

        function q() {
            return new Quaternion(f32(), f32(), f32(), f32());
        }

        function str() {
            const strLen = u16();
            let r = '';
            for (let i = 0; i < strLen; i++)
                r += String.fromCharCode(b.getUint8(pos++));
            return r;
        }

        const meshCounts = u16();
        this.meshes = [];

        for (let i = 0; i < meshCounts; i++) {
            const dataLen = u32();
            const indexCount = u32();

            const dataSlice = b.buffer.slice(pos, pos + dataLen);
            const indexSlice = b.buffer.slice(pos + dataLen, pos + dataLen + indexCount * 2);
            pos += dataLen + indexCount * 2;
            this.meshes.push({ dataSlice, indexSlice });
        }

        const bonesCount = u16();
        this.bonesNames = [];
        for (let i = 0; i < bonesCount; i++)
            this.bonesNames.push(str());

        const armaturesCount = u16();
        this.armatures = [];
        for (let i = 0; i < armaturesCount; i++) {
            const bonesCount = u8();
            const rootId = u16();
            const bones = [];
            for (let j = 0; j < bonesCount; j++) {
                const nameId = u16();
                const index = u16();
                const arr = [];
                for (let k = 0; k < 16; k++)
                    arr.push(f32());
                const matrix = new Matrix(arr);
                const childrenCount = u8();
                const children = [];
                for (let k = 0; k < childrenCount; k++)
                    children.push(u16());
                bones.push({ nameId, name: this.bonesNames[nameId], index, matrix, children });
            }
            this.armatures.push({ bones, rootId });
        }

        const nodesCount = u16();
        this.nodes = [];
        this.nodesMap = {};
        for (let i = 0; i < nodesCount; i++) {
            const name = str();
            const childrenCount = u16();
            const children = [];
            for (let j = 0; j < childrenCount; j++)
                children.push(u16());
            const meshId = u16(true);
            const armatureId = u16(true);
            const pos = v3();
            const rot = q();
            const scale = v3();
            const node = { name, children, meshId, armatureId, pos, rot, scale };
            this.nodes.push(node);
            this.nodesMap[name] = node;
        }

        const actionsCount = u16();
        this.actions = {};
        for (let i = 0; i < actionsCount; i++) {
            const name = str();
            const framesCount = u16();
            const usedBonesCount = u16();
            const usedBones = [];
            for (let j = 0; j < usedBonesCount; j++)
                usedBones.push(u16());

            const transforms = {};
            for (let j = 0; j < usedBonesCount; j++) {
                const boneTransform = [];
                for (let k = 0; k < framesCount; k++)
                    boneTransform.push({ pos: v3(), rot: q() });
                transforms[usedBones[j]] = boneTransform;
            }
            this.actions[name] = { framesCount, transforms };
        }
    }

    getNode(name) {
        return new Node(name, this);
    }
}

module.exports = {
    Model,
    Node,
    Matrix,
    Vector,
    Quaternion,
}