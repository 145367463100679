const Shader = require("@/game/Shader");

class AimShader extends Shader {
    __getShaders() {
        return {
            vert:
            // language=Glsl
                'precision highp float;attribute vec2 f;uniform vec4 a;uniform float c;varying vec2 m;' +
                'void main(){m=f*2.-1.;gl_Position=vec4(a.xy*2.-vec2(a.z,a.z*c)+vec2(a.z,a.z*c)*(m*.5+.5)*2.-1.,0,1);}',
            frag:
            // language=Glsl
                'precision mediump float;varying vec2 m;uniform vec2 h;const vec4 p=vec4(.12,.2,.68,.01);void main(){vec2 a=abs(m);' +
                'float c=p.x+h.y*p.z,d=c+p.y,tc=smoothstep(c,c+p.w,max(a.x,a.y)-min(a.x,a.y)*1.5),r=smoothstep(d+p.w,d,length(a));gl_FragColor=vec4(vec3(1.,.05,.15),tc*r);}'
        }
    }

    bind(ar) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
    }

    __initUniforms() {

        // aye
        return {
            params: this.gl.getUniformLocation(this.program, 'a'),
            ar: this.gl.getUniformLocation(this.program, 'c'),
            sizes: this.gl.getUniformLocation(this.program, 'h'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'f')
        }
    }

    bindUniforms(x, y, maxS, s, r) {
        const gl = this.gl;
        gl.uniform4f(this.uniforms.params, x, y, maxS, 0);
        gl.uniform2f(this.uniforms.sizes, maxS, s);
    }
}

module.exports = AimShader;