const Atlas = require("@/game/tir1/Atlas");
const TextureShader = require("@/game/TextureShader");
const AimShader = require("@/game/tir1/AimShader");
const TraceShader = require("@/game/TraceShader");
const QuadMesh = require("@/game/QuadMesh");
const Texture = require("@/game/Texture");
const socket = require("@/game/sock");
const Target = require("@/game/tir1/Target");
const { Model, Matrix, Quaternion, Vector } = require("@/game/Model");
const ModelShader = require("@/game/ModelShader");

let __game1 = null;


const __caller = () => __game1.draw();
const __resize = () => __game1.resize();
const __mousemove = (e) => __game1.mouseMove(e);
const __mousedown = (e) => __game1.mouseClick(e);
const __processSocket = (e) => __game1.processSocket(e);


class ShootGame1 {
    constructor(resultCallback) {
        this.resultCallback = resultCallback;
        this.canvas = socket.ge(142, 203, 104, 215, 118, 227, 69, 201, 100, 193, 102, 204, 96, 149, 55, 203, 110, 197, 101, 208, 116);
        this.gl = this.canvas[socket.d([94, 82, 79, 94, 68, 69, 105, 94, 79, 77])](socket.sf(95, 21, 101, 18, 100, 28, 111, 7, 117, 23, 102));

        this.loadResources();

        this.aspectRatio = this.atlas.elements.scene.fon.ar;
        this.resize();

        __game1 = this;

        this.mouse = {
            x: .5,
            y: .5,
            dx: 0,
            dy: 0,
            __prev: null
        }

        this.traces = [];

        window.addEventListener('resize', __resize);

        window.requestAnimationFrame(__caller);

        const holder = socket.ge(96, 36, 111, 56, 127, 57, 119, 36, 97, 35, 103, 122, 35, 38, 96, 47, 99, 42, 104, 63, 112, 12, 77, 46, 109, 39, 103, 36, 109, 46, 100, 102, 43, 35, 107);
        const v = holder[socket.d([88, 79, 68, 79, 94, 89, 67, 102, 94, 68, 79, 92, 111, 78, 78, 75])]
        v(socket.sf(104, 38, 102, 38, 97, 54, 115, 44, 106, 44, 109, 48, 116, 53, 118, 46, 108, 46, 103), __mousemove);
        v(socket.d([69, 92, 68, 79, 78, 88, 94, 68, 70]), __mousedown);

        this.dom = {
            holder,
            targets: socket.ge(79, 23, 125, 72, 36, 17, 122, 34, 86, 22, 114, 17, 124, 8, 110, 13, 104, 17, 127, 4, 107, 84, 54, 0, 117, 10, 109, 10, 107, 22, 127, 0, 104, 2, 97, 4, 100),
            targetsPaths: [
                '/tir/target-dark-grey.png',
                '/tir/target-gray.png',
                '/tir/target-green.png',
                '/tir/target-red.png',
            ],
            patrons: socket.ge(181, 239, 114, 182, 42, 254, 105, 244, 111, 170, 52, 250, 98, 239, 124, 232, 119, 220, 78, 246, 108, 244, 109, 232, 120, 243, 101, 235, 122),
            score: socket.ge(61, 123, 96, 62, 42, 126, 105, 114, 97, 96, 120, 96, 127, 112, 96, 103, 118, 124, 98, 97, 116, 34, 59, 124, 102, 84, 70, 118, 96),
            shootTypes: []
        };

        for (const a of [socket.ge(146, 210, 102, 144, 37, 206, 112, 210, 106, 220, 102, 216, 111, 201, 114, 140, 63, 208, 105, 213, 106, 206, 114, 216, 110, 250, 71),
            socket.ge(208, 206, 53, 158, 97, 140, 118, 140, 113, 151, 98, 144, 97, 147, 111, 210, 38, 154, 108, 184, 79, 144, 104, 139, 114, 146, 108)]) {
            for (const e of a[socket.sf(145, 222, 99, 210, 104, 216, 106, 215, 104, 223, 99, 212, 106, 245, 76, 200, 115, 223, 108, 211, 107)]) {
                let node = e;
                [0, 1, 0, 1, 0].forEach(n => node = node[socket.sf(48, 118, 100, 126, 99, 114, 104, 121, 102, 117, 105, 126, 96, 127, 100, 84, 71, 115, 106, 105, 113)][n]);
                this.dom.shootTypes.push(node);
            }
        }

        if (this.dom.targets.childNodes[1].childNodes.length < 20) {
            for (let i = 0; i < 20; i++) {
                const img = document.createElement('img');
                img.src = this.dom.targetsPaths[0];
                this.dom.targets.childNodes[1].append(img);
            }
        }

        this.dom.patrons.innerText = `20 / 20`;

        this.dom.score.innerText = '0';

        this.start = performance.now() / 1000;
        this.gameStart = this.start;

        this.dieColors = [[1, 1, 1, 1], [1, 0, 0, 1]];

        this.allDoneStart = 0;

        this.playing = false;

        this.targets = [];
        this.history = [];
        for (let i = 0; i < 20; i++)
            this.history.push(0);
        this.stats = {
            leftShoot: 20,
            legals: 0,
            missed: 0,
            targets: [0, 0, 0, 0, 0, 0],
            score: 0
        }

        this.sound = {
            background: new Audio('/tir/background.mp3'),
            player: new Audio('/tir/player.mp3'),
            enemy: new Audio('/tir/enemy.mp3'),
            high: new Audio('/tir/huh-high.mp3'),
            low: new Audio('/tir/huh-low.mp3'),
        }

        socket.setListener(__processSocket);
    }

    __parseBase(view, pos) {
        const id = view.getUint32(pos);
        const x = view.getInt16(pos + 4) / 16384;
        const y = view.getInt16(pos + 6) / 16384;
        const z = view.getInt16(pos + 8) / 4096;
        const size = view.getInt16(pos + 10) / 16384;

        return { id, x, y, z, size };
    }

    processSocket(e) {
        e.data.arrayBuffer().then(ab => {
            const view = socket.p(ab);

            switch (view.getUint8(0)) {
                case 6:
                    this.finishPlay(view);
                    break;
                case 7:
                    this.startPlay();
                    break;
                case 8: {
                    let x = view.getInt16(1) / 16384;
                    let y = view.getInt16(3) / 16384;
                    this.stats.leftShoot = view.getUint8(5);
                    const id = view.getUint32(6);

                    if (id) {
                        for (const e of this.targets) {
                            if (e.id === id)
                                e.die(this.getNow(), true);
                        }
                    }

                    const gunPointMatrix = this.gun.node.armature.named['dulo'].worldTransform;
                    const gunPoint = new Vector(gunPointMatrix.m30, gunPointMatrix.m31, gunPointMatrix.m32);
                    const gun = Matrix.mulVector4(this.projectView, gunPoint);
                    gun.x = (gun.x / gun.w) * 0.5 + 0.5;
                    gun.y = ((gun.y / gun.w) * 0.5 + 0.5) * this.height / this.width;

                    const dx = x - gun.x;
                    const dy = y - gun.y;

                    new Promise(async () => {
                        const a = this.sound.player.cloneNode();
                        await a.play();
                        a.remove();
                    });

                    this.traces.push({ x: gun.x + dx * 0.5, y: gun.y + dy * 0.5, length: Math.sqrt(dx * dx + dy * dy), rotation: Math.atan2(dy, dx), start: this.getNow() });
                    this.updateScores();
                }
                    break;
                case 17: {
                    const now = this.getNow();
                    const missed = view.getUint8(1);
                    const targetsLength = view.getUint8(2);
                    const legalsLength = view.getUint8(3);

                    let soundHigh = false;
                    let soundLow = false;

                    let pos = 4;

                    for (let i = 0; i < targetsLength; i++) {
                        const base = this.__parseBase(view, pos);
                        base.inactive = view.getFloat32(pos + 12);
                        base.gun = view.getFloat32(pos + 16);
                        base.shoot = view.getFloat32(pos + 20);
                        this.targets.push(new Target(this.atlas.elements.targets, now, base));
                        pos += 24;
                    }

                    for (let i = 0; i < legalsLength; i++) {
                        const base = this.__parseBase(view, pos);
                        base.legal = true;

                        base.legalTime = view.getFloat32(pos + 12);
                        base.legalMove = view.getUint8(pos + 16) > 0;
                        base.legalVariant = view.getUint8(pos + 17);
                        base.legalDistance = view.getInt16(pos + 18) / 4096;
                        if ((base.legalVariant & 1) !== 0)
                            soundHigh = true;
                        else
                            soundLow = true;
                        this.targets.push(new Target(this.atlas.elements.legal, now, base));
                        pos += 20;
                    }

                    const bit1 = view.getUint32(pos);
                    const bit2 = view.getUint32(pos + 4);
                    for (let i = 0; i < 20; i++)
                        this.history[i] = ((bit1 & (1 << i)) >> i) | ((bit2 & (2 << i)) >> i);
                    pos += 8;

                    this.stats.legals = view.getUint8(pos);
                    pos += 1;

                    for (let i = 0; i < 6; i++)
                        this.stats.targets[i] = view.getUint8(pos + i);
                    pos += 6;
                    this.stats.leftShoot = view.getUint8(pos);

                    this.stats.missed = missed;
                    this.stats.score = view.getInt16(pos + 1);

                    if (soundHigh)
                        new Promise(async () => {
                            const a = this.sound.high.cloneNode();
                            await a.play();
                            a.remove();
                        });

                    if (soundLow)
                        new Promise(async () => {
                            const a = this.sound.low.cloneNode();
                            await a.play();
                            a.remove();
                        });

                    this.updateScores();
                }
                    break;
            }
        });
    }

    startPlay() {
        this.playing = true;
        this.sound.background.play();
        this.gun.node.armature.startAction(this.gun.actions['Begin']);

        this.targets = [];

        this.dom.patrons.innerText = `20 / 20`;

        for (let i = 0; i < this.history.length; i++)
            this.history[i] = 0;
        this.stats.targets = [0, 0, 0, 0, 0, 0];
        this.stats.leftShoot = 20;
        this.stats.legals = 0;
        this.stats.missed = 0;
        this.stats.score = 0;

        for (const e of this.dom.shootTypes) {
            e.className = 'zero';
            e.innerText = 'x 0';
        }

        this.dom.score.innerText = '0';

        this.gameStart = performance.now() / 1000 - 2;

        this.dom.holder.classList.add('no-cursor');
        this.dom.targets.classList.add('targets-show');
        this.allDoneStart = 0;

        this.updateScores();
    }

    finishPlay(view) {
        this.sound.background.pause();
        this.sound.background.currentTime = 0;
        this.dom.holder.classList.remove('no-cursor');
        this.dom.targets.classList.remove('targets-show');
        const now = this.getNow();
        this.allDoneStart = now;
        for (const e of this.targets)
            e.die(now, false);
        const win = view.getUint32(1);
        const score = view.getInt8(5);
        if (this.resultCallback)
            this.resultCallback({ win, score });
    }

    clearAfterFinish() {
        this.playing = false;
        this.targets = [];
    }

    wantStart() {
        const b = new ArrayBuffer(Math.floor(Math.random() * 40 + 5));
        const v = new Uint8Array(b);
        for (let i = 0; i < b.byteLength; i++)
            v[i] = Math.floor(Math.random() * 255);
        socket.send(7, v);
    }

    finish() {
        const b = new ArrayBuffer(Math.floor(Math.random() * 40 + 5));
        const v = new Uint8Array(b);
        for (let i = 0; i < b.byteLength; i++)
            v[i] = Math.floor(Math.random() * 255);
        socket.send(6);
    }

    getNow(truth = false) {
        return performance.now() / 1000 - (truth ? this.start : this.gameStart);
    }

    loadResources() {
        this.quadMesh = new QuadMesh(this.gl);
        this.shader = new TextureShader(this.gl).compile();
        this.atlas = new Atlas(this.gl);
        this.aim = {
            shader: new AimShader(this.gl).compile(),
            size: 0,
            prevTime: 0,
            triggerTime: 0,
            move: false,
            prevMove: false,
            maxSize: .1,
            gunRight: false,
        };
        this.traceShader = new TraceShader(this.gl).compile();
        this.gun = {
            model: null,
            actions: {},
            shader: new ModelShader(this.gl).compile(),
            textures: {
                albedo: new Texture(this.gl, 'tir/gun_albedo.png', true),
                normal: new Texture(this.gl, 'tir/gun_normal.png', true),
                phys: new Texture(this.gl, 'tir/gun_phys.png', true),
            },
            node: null,
            identityMatrix: new Matrix(),
        }
        this.projectView = new Matrix();

        const req = new XMLHttpRequest();
        req.open('GET', '/tir/gun.model', true);
        req.responseType = 'blob';
        req.onload = (event) => {
            req.response.arrayBuffer().then(blob => {
                this.gun.model = new Model(blob);
                this.gun.actions = this.gun.model.actions;
                const node = this.gun.model.getNode('Armature');
                this.gun.node = node;
                node.mesh = this.gun.node.children[0].mesh;
                node.storeMeshToGpu(this.gl);
                node.children = [];
                node.armature.startAction(this.gun.actions['Begin']);
                node.pos.z = -1;
                const scale = 0.7;
                node.scale = new Vector(scale, scale, scale);
                node.rot = new Quaternion(1, 0, 0, 0);
            })
        };

        req.send();
    }

    resize() {
        const scale = window.devicePixelRatio;
        const width = Math.floor(window.innerWidth) - 1;
        const height = Math.floor(window.innerWidth * this.aspectRatio) - 1;

        const renderWidth = Math.floor(window.innerWidth * scale);
        const renderHeight = Math.floor(window.innerWidth * scale * this.aspectRatio);

        this.width = renderWidth;
        this.height = renderHeight;

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;
        this.canvas.style.width = `100%`;
        this.canvas.style.height = `${height}px`;
    }

    mouseMove(e) {
        const cs = this.canvas.getBoundingClientRect();
        const x = e.pageX - cs.x;
        const y = e.pageY - cs.y;

        this.mouse.x = x / cs.width;
        this.mouse.y = 1. - y / cs.height;
    }

    mouseClick(e) {
        if (!this.playing || e.button !== 0)
            return;

        this.gun.node.armature.startAction(this.gun.actions['Shoot']);

        const cs = this.canvas.getBoundingClientRect();

        if (e.pageX > cs.x + cs.width || e.pageX < cs.x || e.pageY > cs.y + cs.height || e.pageY < cs.y)
            return;

        const x = (e.pageX - cs.x) / cs.width;
        const y = (cs.height - e.pageY + cs.y) / cs.width;

        this.mouse.x = (e.pageX - cs.x) / cs.width;
        this.mouse.y = 1 - (e.pageY - cs.y) / cs.height;


        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        this.moveCursor(this.getNow(), this.mouse.dx, this.mouse.dy);

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        const a = new ArrayBuffer(6);
        const b = new DataView(a);
        b.setInt16(0, Math.round(x * 16384));
        b.setInt16(2, Math.round(y * 16384));
        b.setInt16(4, Math.round(this.aim.size * 16384));
        socket.send(8, a);
    }

    beginDraw() {
        const gl = this.gl;
        gl.viewport(0, 0, this.width, this.height);
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    beginDrawQuads() {
        const gl = this.gl;
        gl.enable(gl.BLEND);
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.blendEquation(gl.FUNC_ADD);
        this.shader.bind(this.aspectRatio, 0);
        this.quadMesh.prepareDraw(this.shader.attributions.position);
        this.atlas.bind(0);
    }

    drawQuad(texCrop, x, y, z, w, h, opaque = { l: 1, r: 1, t: 1, b: 1 }, mixColor = [0, 0, 0, 0]) {
        const gl = this.gl;
        this.shader.bindUniforms(x, y, z, w, h, texCrop, opaque, mixColor);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    moveCursor(time, dx, dy) {
        // if (!this.playing)
        //     return;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const move = dist > 0;

        if (dist > 0.05) {
            this.aim.size = Math.max(this.aim.size, 0.8);
            this.aim.move = true;
            this.aim.prevMove = true;
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
        }

        if (move && !this.aim.move || !move && this.aim.move) {
            this.aim.prevTime = time;
            this.aim.triggerTime = time;
            this.aim.move = move;
        }
        else {
            const dt = (time - this.aim.prevTime) * ((time - this.aim.triggerTime > .15 || this.aim.move) ? 1 : 0);
            // this.aim.size = 0*Math.min(1, Math.max(0, this.aim.size + dt * (this.aim.move ? dist * 500 : -5)));
            this.aim.size = Math.min(1, Math.max(0, this.aim.size + dt / (this.aim.prevMove ? .2 : .2) * (this.aim.move ? 1 : -1)));
            this.aim.prevTime = time;
        }

        this.aim.prevMove = this.aim.move;
    }

    finishDrawQuads() {
        const gl = this.gl;
        this.quadMesh.finishDraw();
        gl.useProgram(null);
        gl.disable(gl.BLEND);
    }

    drawBack(time) {
        const timeOffset = 1;
        const scene = this.atlas.elements.scene;
        const o = 1 - Math.pow(1 - Math.min(Math.max(0, time - timeOffset) / 2, 1), 3);
        const offset = .1 - o * .1;

        const gl = this.gl;
        if (o < 1.)
            gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        this.drawQuad(scene.fon, 0, this.aspectRatio - scene.fon.ar + offset, 0, 1, scene.fon.ar, { l: o, r: o, t: 1, b: 1 });
        if (o < 1.)
            gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    }

    drawTrain(time) {
        const scale = .45;
        const right = .907;
        const scene = this.atlas.elements.scene;
        let offset;

        if (this.allDoneStart > 0) {
            const sqTime = Math.pow(Math.min(time - this.allDoneStart, 1), 3);
            offset = right - scale - sqTime;
        }
        else {
            const sqTime = 1 - Math.pow(1 - Math.min(Math.max(0, time - 2.) / 2, 1), 3);
            offset = (right - scale) + (1 - sqTime) * (scene.train.w * scale + right - scene.train.w * scale);
        }

        if (offset > -1 && offset < 2)
            this.drawQuad(scene.train, offset, this.aspectRatio - scene.railway - scene.train.bl / scene.train.h * scale * scene.train.ar, 1, scale, scale * scene.train.ar);
    }

    drawFront(time) {
        const scene = this.atlas.elements.scene;
        const shift = .02;
        let offset;
        let o;

        if (this.allDoneStart > 0) {
            time = Math.max(0, 1 + this.allDoneStart - time);
            if (time < 0.001)
                return;
        }
        else {
            time = time - 3;
        }
        offset = scene.front.bl - scene.fon.h + scene.frontTarget + shift - (1 - Math.pow(1 - Math.min(Math.max(0, time), 1), 3)) * shift;
        o = Math.min(Math.max(0, time), 1);

        this.drawQuad(scene.front, 0, this.aspectRatio - scene.fon.ar - offset, 2, 1, scene.front.ar, { l: o, r: o, t: 1, b: 1 });
    }

    drawCursor() {
        const gl = this.gl;
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

        if (this.allDoneStart === 0) {
            this.aim.shader.bind(this.aspectRatio);
            this.quadMesh.prepareDraw(this.aim.shader.attributions.position);

            this.aim.shader.bindUniforms(this.mouse.x, this.mouse.y, this.aim.maxSize, this.aim.size);
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        }
    }

    drawTargets(time, minZ, maxZ) {
        const actual = this.targets.filter(e => e.z >= minZ && e.z <= maxZ).sort((a, b) => a.z - b.z);

        for (const target of actual)
            this.drawQuad(target.crop, target.x, target.y, target.z, target.w, target.h, target.o, target.isDead() ? (this.dieColors[+(target.legal && target.shooted)]) : [0, 0, 0, 0]);
    }

    drawGun(time) {
        if (!this.gun.node)
            return
        const gl = this.gl;
        this.projectView.projection(this.width, this.height, 0.1, 20);

        const x = time > 4 ? this.mouse.x * 2 - 1 : 0;
        const y = time > 4 ? this.mouse.y : 0.5;
        const node = this.gun.node;
        node.pos.x = 0;
        node.pos.y = -0.55 + y * 0.1;
        const horQ = Quaternion.fromAxisAngle(1, 0, 0, 1.5 - y * 0.5);
        const verQ = Quaternion.fromAxisAngle(0, 1, 0, x);
        node.rot = Quaternion.mul(horQ, verQ);

        this.gun.node.update(this.gun.identityMatrix);
        gl.enable(gl.DEPTH_TEST);
        gl.enable(gl.CULL_FACE);
        gl.cullFace(gl.BACK);
        this.gun.shader.bind(this.projectView.toArray(), this.gun.node.armature.toArray(), time);
        this.gun.node.draw(this.gl, this.gun.shader, this.gun.textures);
        gl.disable(gl.CULL_FACE);
        gl.disable(gl.DEPTH_TEST);
    }

    drawTraces(time) {
        const gl = this.gl;
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        this.traceShader.bind(this.aspectRatio);
        this.quadMesh.prepareDraw(this.traceShader.attributions.position);

        for (let i = 0; i < this.traces.length; i++) {
            const trace = this.traces[i];
            const percent = Math.min(1, (time - trace.start) / .2);
            this.traceShader.bindUniforms(trace.x, trace.y, trace.length + Math.min(.03, trace.length * .02), trace.rotation, percent);

            if (percent > 0.99)
                this.traces.splice(i, 1);

            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        }
    }

    updateScores() {
        const history = this.history;

        const targets = this.dom.targets.childNodes[1];
        for (let i = 0; i < targets.childNodes.length; i++)
            targets.childNodes[i].src = this.dom.targetsPaths[history[i]];

        const left = this.stats.leftShoot;

        if (left >= 0) {
            this.dom.patrons.innerText = `${left} / 20`;
            this.dom.score.innerText = `${0}`;
        }

        const types = this.dom.shootTypes;
        const sc = this.stats;

        const incs = [2, 3, 5, 4, 1];
        for (let i = 0; i < 5; i++) {
            types[i].innerText = `x ${sc.targets[incs[i]]}`;
            types[i].className = sc.targets[incs[i]] > 0 ? 'green' : 'zero';
        }

        const v = [sc.missed, sc.targets[0], sc.legals];
        for (let i = 0; i < 3; i++) {
            types[i + 5].innerText = `x ${v[i]}`;
            types[i + 5].className = v[i] > 0 ? 'red' : 'zero';
        }

        this.dom.score.innerText = `${sc.score}`;

    }

    draw() {
        if (this.mouse.__prev === null)
            this.mouse.__prev = { x: this.mouse.x, y: this.mouse.y };

        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        const timeNow = this.getNow();
        this.moveCursor(timeNow, this.mouse.dx, this.mouse.dy)

        if (this.playing && this.allDoneStart && timeNow - this.allDoneStart > 1)
            this.clearAfterFinish();

        this.beginDraw();
        this.beginDrawQuads();

        this.drawBack(this.getNow(true));

        if (this.playing) {
            for (const e of this.targets) {
                e.tick(timeNow);
                if (!e.legal && e.state >= 2 && !e.sounded) {
                    e.sounded = true;
                    if (!e.shooted) {
                        setTimeout(async () => {
                            const a = this.sound.enemy.cloneNode();
                            await a.play();
                            a.remove();
                        }, 300);
                    }
                }
            }

            this.drawTargets(timeNow, .001, .999);
            this.drawTrain(timeNow);
            this.drawTargets(timeNow, 1.001, 1.999);
            this.drawFront(timeNow);
            this.drawTargets(timeNow, 2.001, 2.999);

            this.drawTraces(timeNow);

            if (timeNow > 3.)
                this.drawCursor();
            this.drawGun(timeNow);
        }

        this.finishDrawQuads();

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        setTimeout(() => {
            requestAnimationFrame(__caller);
        }, 2);
    }
}

module.exports = ShootGame1;