<template>
    <div v-if="!r" style="padding-top: 40px">
        <a href="/1" style="display: block;padding: 20px;">Тир 1</a>
        <a href="/2" style="display: block;padding: 20px;">Тир 2</a>
        <a href="/3" style="display: block;padding: 20px;">Тир 3</a>
        <a href="/4" style="display: block;padding: 20px;">Тир 4</a>
    </div>
    <ShootGame1 v-else-if="r === 1"/>
    <ShootGame2 v-else-if="r === 2"/>
    <ShootGame3 v-else-if="r === 3"/>
    <ShootGame4 v-else-if="r === 4"/>
</template>

<script>
import ShootGame1 from "@/ShootGame1.vue";
import ShootGame2 from "@/ShootGame2.vue";
import ShootGame3 from "@/ShootGame3.vue";
import ShootGame4 from "@/ShootGame4.vue";

export default {
    name: 'App',
    components: { ShootGame1, ShootGame2, ShootGame3, ShootGame4 },
    data: () => ({
        r: +window.location.pathname.replaceAll('/', '')
    }),
}
</script>
<style lang="css">

.no-cursor {
    cursor: none;
}

@font-face {
    font-family: 'GameFont';
    src: url('@/assets/font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BetterVcr';
    src: url('@/assets/better-vcr.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
</style>
