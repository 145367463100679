<template>
    <div>
        <div style="user-select: none" class="game-4">
            <div class="upper-game">
                <input @click="start" type="button" value="start">
                <div class="close">
                    ЗАКРЫТЬ X
                </div>
            </div>
            <div id="shootGame4-holder">
                <div style="position: relative;width: 0;height: 0" id="game-4-onscreen" class="onscreen"/>
                <canvas id="shootGame4" style="margin: 0;padding: 0;flex: 0 0"/>
            </div>
            <div class="bottom-game">
                <div class="content" id="game-4-bottom">
                    <div class="block">
                        <div class="block-top"/>
                        <div class="block-main">
                            <div class="text-m" style="display: flex;align-items: center">Выбейте цель:
                                <img src="/tir4/shapes.png" style="flex: 0 0 3vw;display: block;width: 3vw;height: 3vw;">
                            </div>
                        </div>
                        <div class="block-bottom"/>
                    </div>
                    <div class="block">
                        <div class="block-top"/>
                        <div class="block-main">
                            <div class="text-m">Средняя скорость реакции</div>
                            <div class="text-h"><span id="game-4-reaction">0.00</span> м/с</div>
                        </div>
                        <div class="block-bottom"/>
                    </div>
                    <div class="block">
                        <div class="block-top"/>
                        <div class="block-main" style="flex-direction: row">
                            <div class="type">
                                <img src="/tir4/timeout.png" class="img">
                                <div class="info">
                                    <div class="title">POINTS</div>
                                    <div class="score">-5</div>
                                </div>
                                <div class="times" id="game-4-timeout">x0</div>
                            </div>
                            <div style="flex: 0 0 2vw"></div>
                            <div class="type">
                                <img src="/tir4/miss.png" class="img">
                                <div class="info">
                                    <div class="title">POINTS</div>
                                    <div class="score">-5</div>
                                </div>
                                <div class="times" id="game-4-milk">x0</div>
                            </div>
                        </div>
                        <div class="block-bottom"/>
                    </div>
                    <div class="points">
                        <div class="points-left"/>
                        <div class="points-main">
                            <div class="title">POINTS</div>
                            <div class="score" id="game-4-score">0</div>
                        </div>
                        <div class="points-right"/>
                    </div>
                    <div class="block">
                        <div class="block-top"/>
                        <div class="block-main">
                            <div class="text-m">Выбито целей</div>
                            <div class="text-h"><span id="game-4-success">0</span> <span style="font-size: 1vw;color: #999">/ 20</span></div>
                        </div>
                        <div class="block-bottom"/>
                    </div>
                </div>
            </div>
        </div>
        <Shop ref="shop"/>
    </div>
</template>

<script>
import ShootGame4 from "@/game/shootGame4";
import Shop from "@/Shop.vue";

export default {
    components: { Shop },
    data: () => ({
        game: null,
    }),
    methods: {
        start() {
            this.game.wantStart();
        },
    },
    mounted() {
        this.game = new ShootGame4(this.$refs.shop.onResult);
    },
    beforeUnmount() {
        this.game.finish();
    }
}
</script>


<style lang="less">

@keyframes game-4-score {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.game-4 {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', serif;


    #shootGame4-holder {
        display: flex;
        flex-direction: column;
    }

    .onscreen {
        .block {
            display: flex;
            width: 4vw;
            height: 4vw;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: absolute;
            text-align: center;
            animation-duration: 900ms;
            animation-name: game-4-score;
            animation-fill-mode: forwards;
        }

        .info {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 5vw;
            font-size: .8vw;
            color: #ffffff;

            .line {
                height: .1vw;
                width: 100%;
                position: relative;
                background: #ffffff;
                margin: .1vw 0;

                .dot {
                    position: absolute;
                    height: .6vw;
                    width: .6vw;
                    right: -.2vw;
                    top: -.25vw;
                    background: #ffffff;
                    border-radius: .3vw
                }
            }
        }

        .time {
            font-size: 0.8vw;
            color: #eeeeee;
        }

        .text {
            font-size: 2vw;
            color: #ffffff;
        }

        .red {
            color: #FF0C25;
        }

        .green {
            color: #13D93E;
        }
    }

    .upper-game {
        border: 2px solid #018A48;
        box-sizing: border-box;
        height: 3vw;
        background: #080D05;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 5%;
        align-items: center;

        .close {
            color: #ffffff;
            font-size: 1vw;
        }
    }

    .bottom-game {
        padding: 0;
        margin: 0;
        position: relative;
        width: 100%;
        height: 8.4vw;
        border: 2px solid #00FF56;
        background: #080D05;
        box-sizing: border-box;

        .content {
            user-select: none;
            position: absolute;
            width: 100%;
            height: 70%;
            top: 15%;
            left: 0;
            display: flex;
            justify-content: space-around;
            align-items: stretch;

            .block {
                color: #ffffff;
                display: flex;
                flex-direction: column;

                .type {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .img {
                        flex: 0 0 4vw;
                        display: block;
                        width: 4vw;
                        height: 4vw;
                    }

                    .info {
                        flex: 0 0 auto;
                        padding: 0 0.5vw;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        box-sizing: border-box;
                    }

                    .title {
                        font-size: .8vw;
                    }

                    .score {
                        font-size: 2vw;
                        color: #ffffff;
                        font-weight: 700;
                    }

                    .times {
                        flex: 0 0 auto;
                        white-space: nowrap;
                        font-size: 1.5vw;
                        color: #EB200A;
                    }
                }

                .target {
                    margin-left: 1vw;
                    display: inline-block;
                    width: 2vw;
                    height: 2vw;
                    transition-duration: 200ms;
                }

                .target-green {
                    background: #33e519;
                }

                .target-red {
                    background: #e54c19;
                }

                .target-yellow {
                    background: #cce519;
                }

                .target-circle {
                    border-radius: 1vw;
                }

                .target-rect {

                }

                .target-rhomb {
                    transform: rotate(45deg);
                }

                .text-h {
                    font-size: 1.5vw;
                    font-weight: 700;
                }

                .text-m {
                    font-size: 1vw;
                }

                .block-top {
                    flex: 0 0 0.5vw;
                    border: 3px solid #018A48;
                    border-bottom: none;
                }

                .block-main {
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 2vw;
                    box-sizing: border-box;
                }

                .block-bottom {
                    flex: 0 0 0.5vw;
                    border: 3px solid #018A48;
                    border-top: none;
                }
            }

            .points {
                color: #ffffff;
                display: flex;
                flex-direction: row;
                text-align: center;

                .title {
                    font-size: 1vw;
                }

                .score {
                    width: 5vw;
                    font-size: 3vw;
                    color: #00FF56;
                    font-weight: 700;
                }

                .points-left {
                    flex: 0 0 0.5vw;
                    border: 6px solid #018A48;
                    border-right: none;
                }

                .points-main {
                    flex: 0 0 3vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 1vw;
                }

                .points-right {
                    flex: 0 0 0.5vw;
                    border: 6px solid #018A48;
                    border-left: none;
                }
            }
        }
    }
}

</style>