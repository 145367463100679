class Target {
    static lastI

    constructor(atlas, time, options = {}) {
        this.id = options.id;

        this.legal = !!options.legal;
        this.legalVariant = options.legalVariant || 0;

        this.atlas = atlas;

        if (this.legal) {
            this.crop = atlas[this.legalVariant];
            this.legalOptions = {
                time: options.legalTime || 5,
                move: options.legalMove,
                distance: options.legalDistance,
            }
        }
        else
            this.crop = atlas.base;

        this.footX = options.x || 0;
        this.footY = options.y || 0;
        this.startFootX = this.footX;
        this.startFootY = this.footY;
        this.height = options.size || 0;

        this.x = options.x || 0;
        this.y = options.y || 0;
        this.z = options.z || 0;
        this.w = options.size || 0;
        this.h = options.size || 0;
        this.o = { l: 1, r: 1, t: 1, b: 1 };
        this.size = options.size || 0;

        this.state = 0;

        this.timeStart = 0;
        this.deadStart = 0;
        this.shooted = false;

        this.times = {
            inactive: options.inactive || 10. + Math.random() * 2.,
            gun: options.gun || 1.2 + Math.random() * .8,
            shoot: options.shoot || .7 + Math.random() * .7,
        }

        this.ends = false;

        this.timeStart = time;

        this.sounded = false;

        this.tick(time);
    }

    die(time, shoot) {
        if (this.deadStart > 0)
            return false;

        this.deadStart = time - this.timeStart;

        if (!this.legal && shoot) {
            this.state = 4;
            this.deadStart += 0.2;
        }

        this.shooted = !!shoot;
        return true;
    }

    isDead() {
        return this.deadStart > 0;
    }

    __tickEnemy(time) {
        if (this.deadStart === 0) {
            if (time > this.times.inactive)
                this.state = 1;
            if (time > this.times.inactive + this.times.gun)
                this.state = 2;
            if (time > this.times.inactive + this.times.gun + this.times.shoot) {
                this.state = 3;
                this.deadStart = time;
            }
        }

        switch (this.state) {
            case 0:
                this.crop = this.atlas.base;
                break;
            case 1:
                this.crop = this.atlas.gun;
                break;
            case 2:
            case 3:
                this.crop = this.atlas.shoot;
                break;
            case 4:
                this.crop = this.atlas.dead;
        }
    }

    __tickLegal(time) {
        if (time > this.legalOptions.time && this.deadStart === 0)
            this.deadStart = time;
    }

    getLifetime(time) {
        return time - this.timeStart;
    }

    tick(time) {
        time -= this.timeStart;

        if (this.legal)
            this.__tickLegal(time);
        else
            this.__tickEnemy(time);

        const crop = this.crop;
        const picHeight = crop.h / (crop.h - crop.b2 - crop.bl);
        const picWidth = picHeight / crop.ar;

        const deadTime = .15;

        if (this.legal && this.legalOptions.move) {
            const percent = time / (this.legalOptions.time + deadTime);
            const steps = Math.floor(this.legalOptions.distance * 16);
            const scale = .2;
            const sin = Math.sin(Math.PI * percent * steps);
            this.footX = this.startFootX + (percent + sin / (Math.PI * steps)) * this.size * scale * this.legalOptions.distance;
            this.footY = this.startFootY + Math.sqrt((sin + 1) / 2) * .02 * this.size * scale;
        }

        this.h = this.height * picHeight;
        this.w = this.height * picWidth;
        this.x = this.footX - picWidth * this.height * crop.bx / crop.w;
        this.y = this.footY - picHeight * this.height * crop.bl / crop.h;

        const percent = Math.min(1, time / .15);
        this.h *= percent;

        if (this.deadStart > 0) {
            const percent = 1 - Math.max(0, Math.min(1, (time - this.deadStart) / deadTime));
            this.ends = percent < 0.001;
            this.o.t = percent;
            this.o.b = percent;
        }
    }
}

module.exports = Target