const Shader = require("@/game/Shader");

class TextureShader extends Shader {
    __getShaders() {
        return {
            // language=Glsl
            vert:
                'precision highp float;attribute vec2 a;varying vec4 m;uniform vec4 b,c;uniform float e,f;void main(){m=vec4(vec2(a.x,1.-a.y)*c.zw+c.xy,a),gl_Position=vec4((a*b.zw+b.xy)*vec2(1,e)*2.-1.,f,1);}'
            /*`precision highp float;
             attribute vec2 c;
             uniform float k;
             uniform vec4 a;
             varying vec4 m;
             uniform vec4 d;
             uniform float f;
             void main() {
                 m = vec4(vec2(c.x, 1. - c.y) * d.zw + d.xy, c);
                 gl_Position = vec4((c * a.zw + a.xy) * vec2(1., k) * 2. - 1., f, 1.0);
             }`*/,
            // language=Glsl
            frag:
                'precision mediump float;varying vec4 m;uniform sampler2D i;uniform vec4 q,z;' +
                'void main(){vec4 a=texture2D(i,m.xy).rgba;a*=mix(q.x,q.y,m.y),a*=mix(q.z,q.w,m.w),gl_FragColor=vec4(mix(vec3(a.rgb),z.rgb,(1.-a.a)*z.a),a.a);}'
            /*  `precision mediump float;
               varying vec4 m;
               uniform sampler2D i;
               uniform vec4 q;
               uniform vec4 z;
               void main() {
                   vec4 c = texture2D(i, m.xy).rgba;
                   c *= mix(q.x, q.y, m.y);
                   c *= mix(q.z, q.w, m.w);
                   gl_FragColor = vec4(mix(vec3(c.rgb), z.rgb, (1. - c.a) * z.a), c.a);
               }`*/
        }
    }

    bind(ar, img) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
        this.gl.uniform1i(this.uniforms.image, img);
    }

    __initUniforms() {
        return {
            screenCrop: this.gl.getUniformLocation(this.program, 'b'),
            textureCrop: this.gl.getUniformLocation(this.program, 'c'),
            ar: this.gl.getUniformLocation(this.program, 'e'),
            z: this.gl.getUniformLocation(this.program, 'f'),
            image: this.gl.getUniformLocation(this.program, 'i'),
            opaque: this.gl.getUniformLocation(this.program, 'q'),
            mixColor: this.gl.getUniformLocation(this.program, 'z'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'a')
        }
    }

    bindUniforms(x, y, z, w, h, textureCrop, opaque, mixColor) {
        const gl = this.gl;
        gl.uniform1f(this.uniforms.z, -z / 10.);
        gl.uniform4f(this.uniforms.screenCrop, x, y, w, h);
        gl.uniform4f(this.uniforms.textureCrop, textureCrop.x, textureCrop.y, textureCrop.w, textureCrop.h);
        if (typeof opaque === 'number')
            gl.uniform4f(this.uniforms.opaque, opaque, opaque, opaque, opaque);
        else
            gl.uniform4f(this.uniforms.opaque, opaque.l, opaque.r, opaque.t, opaque.b);
        if (mixColor)
            gl.uniform4f(this.uniforms.mixColor, mixColor[0], mixColor[1], mixColor[2], mixColor[3]);
        else
            gl.uniform4f(this.uniforms.mixColor, 1, 1, 1, 1);
    }
}


module.exports = TextureShader;