const Texture = require("@/game/Texture");

class Atlas extends Texture {
    constructor(gl) {
        super(gl, 'tir/atlas.png');

        const size = 2048;

        // left, top, right, bottom, footY, footX, headY
        this.elements = {
            scene: {
                fon: [0, 0, 2048, 675],
                train: [550, 689, 2047, 1121, 1085],
                front: [0, 1788, 2047, 2047, 1999],
                railway: 523,
                frontTarget: 593
            },
            targets: {
                base: [639, 1233, 930, 1781, 1762, 773, 1238],
                gun: [937, 1233, 1322, 1781, 1763, 1173, 1238],
                shoot: [1328, 1233, 1674, 1781, 1764, 1538, 1238],
                dead: [1690, 1233, 2047, 1781, 1747, 1777, 1242],
            },
            legal: [
                [14, 680, 283, 1215, 1205, 100, 693], // man white
                [309, 680, 510, 1215, 1209, 397, 686], // woman red
                [23, 1233, 265, 1781, 1759, 116, 1254], // man blue
                [300, 1233, 629, 1781, 1767, 397, 1246], // woman bardo
            ]
        }

        for (const e of Object.keys(this.elements)) {
            if (this.elements[e] instanceof Array && typeof this.elements[e][0] === 'number') {
                this.elements[e] = this.__processArray(this.elements[e], size);
            }
            else {
                for (const u of Object.keys(this.elements[e])) {
                    if (this.elements[e][u] instanceof Array)
                        this.elements[e][u] = this.__processArray(this.elements[e][u], size);
                    else
                        this.elements[e][u] /= size;
                }
            }
        }
    }

    __processArray(arr, size) {
        const cp = arr.map(e => e / size);
        const w = cp[2] - cp[0], h = cp[3] - cp[1];
        return { x: cp[0], y: cp[1], w, h, ar: h / w, bl: cp[4] ? cp[3] - cp[4] : 0, bx: cp[5] ? cp[5] - cp[0] : 0, b2: cp[6] ? cp[6] - cp[1] : 0 };
    }
}

module.exports = Atlas;