class QuadMesh {
    constructor(gl) {
        this.gl = gl;

        this.vertex = gl.createBuffer();

        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertex);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([0, 0, 0, 1, 1, 0, 1, 1]), gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);
    }

    prepareDraw(index) {
        const gl = this.gl;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertex);
        gl.vertexAttribPointer(index, 2, gl.FLOAT, false, 8, 0);
        gl.enableVertexAttribArray(index);
    }

    draw() {
        const gl = this.gl;
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    finishDraw() {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
    }
}

module.exports = QuadMesh