class Shader {
    constructor(gl) {
        this.gl = gl;
    }

    compile() {
        const gl = this.gl;

        const source = this.__getShaders();

        console.log(this.constructor.name)

        let vertex = gl.createShader(gl.VERTEX_SHADER);
        gl.shaderSource(vertex, source.vert);
        gl.compileShader(vertex);

        console.log('Vert shader compiled successfully: ' + gl.getShaderParameter(vertex, gl.COMPILE_STATUS));
        console.log('Vert shader compiler log: ' + gl.getShaderInfoLog(vertex));

        let fragment = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fragment, source.frag);
        gl.compileShader(fragment);

        console.log('Frag shader compiled successfully: ' + gl.getShaderParameter(fragment, gl.COMPILE_STATUS));
        console.log('Frag shader compiler log: ' + gl.getShaderInfoLog(fragment));

        this.program = gl.createProgram();
        gl.attachShader(this.program, vertex);
        gl.attachShader(this.program, fragment);
        gl.linkProgram(this.program);

        console.log(gl.getProgramParameter(this.program, gl.LINK_STATUS));
        console.log(gl.getProgramInfoLog(this.program));

        gl.deleteShader(vertex)
        gl.deleteShader(fragment)

        this.uniforms = this.__initUniforms();
        this.attributions = this.__initAttributions();

        console.log(this.uniforms)
        console.log(this.attributions)

        return this;
    }

    __getShaders() {
        return {
            vert: '',
            frag: '',
        }
    }

    __initAttributions() {
        return {};
    }

    __initUniforms() {
        return {};
    }

    bind() {
        this.gl.useProgram(this.program);
    }

    bindUniforms() {

    }

    clear() {

    }

}


module.exports = Shader;