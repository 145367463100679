const socket = require("@/game/sock");
const QuadMesh = require("@/game/QuadMesh");
const BackgroundShader = require("@/game/tir4/BackgroundShader");
const Texture = require("@/game/Texture");

let __game4 = null;

const __caller = () => __game4.draw();
const __resize = () => __game4.resize();
const __mousemove = (e) => __game4.mouseMove(e);
const __mousedown = (e) => __game4.mouseClick(e);
const __processSocket = (e) => __game4.processSocket(e);


class ShootGame4 {
    constructor(resultCallback) {
        this.resultCallback = resultCallback;
        this.canvas = document.getElementById('shootGame4');
        this.gl = this.canvas.getContext('webgl');

        this.loadResources();

        this.aspectRatio = 0.33;
        this.resize();

        __game4 = this;

        this.mouse = {
            x: .5,
            y: .5,
            dx: 0,
            dy: 0,
            __prev: null,
            clicks: [],
        }

        for (let i = 0; i < 6; i++)
            this.mouse.clicks.push({ x: 0, y: 0, time: -100 });

        window.addEventListener('resize', __resize);

        window.requestAnimationFrame(__caller);

        const holder = document.getElementById('shootGame4-holder');
        holder.addEventListener('mousemove', __mousemove);
        holder.addEventListener('mousedown', __mousedown);

        this.dom = {
            holder,
            reaction: document.getElementById('game-4-reaction'),
            score: document.getElementById('game-4-score'),
            success: document.getElementById('game-4-success'),
            timeout: document.getElementById('game-4-timeout'),
            milk: document.getElementById('game-4-milk'),
            onscreen: document.getElementById('game-4-onscreen'),
        };

        this.start = performance.now() / 1000;
        this.endTimeAt = -10;
        this.gameStart = this.start;
        this.timeStart = 0;

        this.playing = false;

        this.targets = [];
        this.removed = [];
        this.prevRightTarget = null;

        this.stats = {
            success: 0,
            milk: 0,
            timeout: 0,
            score: 0
        }

        this.median = {
            sum: 0,
            count: 0,
        }

        this.radar = 0;

        this.sound = {
            miss: new Audio('/tir4/miss.mp3'),
            new: new Audio('/tir4/new.mp3'),
            success: new Audio('/tir4/success.mp3'),
            zero: new Audio('/tir4/zero.mp3'),
        };

        socket.setListener(__processSocket);
    }

    loadResources() {
        this.quadMesh = new QuadMesh(this.gl);
        this.backgroundShader = new BackgroundShader(this.gl).compile();
        this.noise = new Texture(this.gl, 'noise.png', false, true);
    }

    resize() {
        const scale = window.devicePixelRatio;
        const width = Math.floor(window.innerWidth) - 1;
        const height = Math.floor(window.innerWidth * this.aspectRatio) - 1;

        const renderWidth = Math.floor(window.innerWidth * scale);
        const renderHeight = Math.floor(window.innerWidth * scale * this.aspectRatio);

        this.width = renderWidth;
        this.height = renderHeight;

        this.canvasSize = { width, height };

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;
        this.canvas.style.width = `100%`;
        this.canvas.style.height = `${height}px`;
    }

    processSocket(e) {
        e.data.arrayBuffer().then(ab => {
            const view = socket.p(ab);

            switch (view.getUint8(0)) {
                case 38:
                    this.finishPlay(view);
                    break;
                case 32:
                    this.startPlay(view);
                    break;
                case 49: {
                    const now = this.getNow(true);
                    const newTargetsLength = view.getUint8(1);
                    const removedLength = view.getUint8(2);

                    let ptr = 3;

                    for (let i = 0; i < newTargetsLength; i++) {
                        this.targets.push({
                            id: view.getUint8(ptr++),
                            type: view.getUint8(ptr++),
                            serverStart: view.getFloat64(ptr),
                            size: view.getFloat32(ptr + 8),
                            times: [0, 2, 4, 6].map(offset => view.getUint16(ptr + 12 + offset) / 1000),
                            x: view.getFloat32(ptr + 20),
                            y: view.getFloat32(ptr + 24),
                            lifeTime: view.getFloat32(ptr + 28),
                            timeStart: now,
                            timeEnd: 0,
                            offset: Math.random() * 6.28
                        });
                        ptr += 32;
                    }

                    for (let i = 0; i < removedLength; i++) {
                        this.removed.push({
                            id: view.getUint8(ptr++),
                            shoot: view.getUint8(ptr++),
                            score: view.getUint8(ptr++),
                        })
                    }

                    this.stats.success = view.getUint8(ptr++);
                    this.stats.timeout = view.getUint8(ptr++);
                    this.stats.milk = view.getUint8(ptr++);
                    this.stats.score = view.getInt8(ptr++);

                    if (newTargetsLength > 0) {
                        new Promise(async () => {
                            const a = this.sound.new.cloneNode();
                            await a.play();
                            a.remove();
                        });
                    }

                    if (this.removed.some(e => e.score < 0)) {
                        new Promise(async () => {
                            const a = this.sound.miss.cloneNode();
                            await a.play();
                            a.remove();
                        });
                    }

                    this.updateScores();
                }
                    break;
                case 8: {
                    const x = view.getInt16(1) / 16384;
                    const y = view.getInt16(3) / 16384;
                    const score = view.getInt8(5);
                    const time = view.getFloat32(6);

                    this.stats.score = view.getUint8(10);
                    this.stats.milk = view.getUint8(11);
                    this.updateScores();

                    if (score >= 0) {
                        this.median.count++;
                        this.median.sum += time;
                    }

                    this.addResult(x, y, score, time);

                    new Promise(async () => {
                        const a = this.sound[score < 0 ? 'zero' : 'success'].cloneNode();
                        await a.play();
                        a.remove();
                    });
                }
                    break;
            }
        });
    }

    addResult(x, y, score, time) {
        const b = document.createElement('div');
        b.className = 'block';
        b.style.left = `${Math.round(this.canvasSize.width * (x - 0.02))}px`;
        b.style.top = `${Math.round(this.canvasSize.width * (this.aspectRatio - y - 0.02))}px`;

        if (time !== 0) {
            const s = document.createElement('div');
            s.innerText = `${Math.round(time * 1000) / 1000}`;
            s.className = 'time';
            b.append(s);
        }

        const s = document.createElement('div');
        s.innerText = `${score}`;
        s.classList.add('text');
        score && s.classList.add(score > 0 ? 'green' : 'red');
        b.append(s);
        this.dom.onscreen.append(b);
        setTimeout(() => b?.remove(), 2000);
    }

    startPlay(view) {
        this.playing = true;
        this.timeEnd = 0;
        this.serverStart = view.getFloat64(1);
        // this.sound.background.play();

        this.targets = [];
        this.mouse.clicks.forEach(e => e.time = -100);
        this.gameStart = performance.now() / 1000;

        this.median.sum = 0;
        this.median.count = 0;

        this.dom.holder.classList.add('no-cursor');
        this.dom.onscreen.innerHTML = '';
        this.timeStart = this.getNow(true);

        this.updateScores();
    }

    finishPlay(view) {
        // this.sound.background.pause();
        // this.sound.background.currentTime = 0;
        this.playing = false;
        this.endTimeAt = this.getNow();
        this.targets.forEach(e => {
            e.dom?.remove();
        })
        this.targets = [];
        this.dom.holder.classList.remove('no-cursor');
        const win = view.getUint32(1);
        const score = view.getInt8(5);
        if (this.resultCallback)
            this.resultCallback({ win, score });
    }

    updateScores() {
        this.dom.score.innerText = this.stats.score;
        this.dom.success.innerText = this.stats.success;
        this.dom.milk.innerText = `x${this.stats.milk}`;
        this.dom.timeout.innerText = `x${this.stats.timeout}`;
        this.dom.reaction.innerText = this.median.count > 0 ? (Math.round(this.median.sum / this.median.count * 100) / 100).toFixed(2) : '0.00';
    }


    getServerTime(time) {
        return time - this.timeStart + this.serverStart;
    }

    mouseMove(e) {
        const cs = this.canvas.getBoundingClientRect();
        this.mouse.x = (e.pageX - cs.x) / cs.width;
        this.mouse.y = this.aspectRatio - (e.pageY - cs.y) / cs.width;
    }

    mouseClick(e) {
        if (!this.playing || e.button !== 0)
            return;

        const cs = this.canvas.getBoundingClientRect();
        const x = (e.pageX - cs.x) / cs.width;
        const y = this.aspectRatio - (e.pageY - cs.y) / cs.width;
        for (let i = this.mouse.clicks.length - 1; i > 0; i--)
            this.mouse.clicks[i] = this.mouse.clicks[i - 1];
        this.mouse.clicks[0] = { x, y, time: this.getNow(true) };

        const now = this.getNow(true);

        const a = new ArrayBuffer(12);
        const b = new DataView(a);
        b.setInt16(0, Math.round(x * 16384));
        b.setInt16(2, Math.round(y * 16384));
        b.setFloat64(4, this.getServerTime(now));
        socket.send(8, a);
    }

    wantStart() {
        const b = new ArrayBuffer(5);
        const v = new Uint8Array(b);
        for (let i = 0; i < b.byteLength; i++)
            v[i] = Math.floor(Math.random() * 255);
        socket.send(32, v);
    }

    getNow(truth = false) {
        return performance.now() / 1000 - (truth ? this.start : this.gameStart);
    }

    draw() {
        if (this.mouse.__prev === null)
            this.mouse.__prev = { x: this.mouse.x, y: this.mouse.y };
        this.mouse.dx = this.mouse.x - this.mouse.__prev.x;
        this.mouse.dy = this.mouse.y - this.mouse.__prev.y;

        this.removed.forEach(e => {
            const target = this.targets.find(u => e.id === u.id);
            if (!target || e.shoot)
                return;
            if (e.score < 0)
                this.addResult(target.x, target.y, e.score, 0);
        })

        const time = this.getNow(true);

        this.targets.forEach(e => {
            if (this.removed.find(u => u.id === e.id)) {
                e.timeEnd = time + 0.5;
            }
        });

        this.targets = this.targets.filter(e => e.timeEnd === 0 || e.timeEnd > time);
        this.removed = [];

        for (let i = this.targets.length - 1; i >= 0; i--) {
            if (this.targets[i].timeEnd) {
                this.targets[i].dom?.remove();
                if (time - this.targets[i].timeEnd > 1)
                    this.targets.splice(i, 1);
            }
        }

        if (this.playing)
            this.radar = Math.min(1., time);
        else
            this.radar = Math.max(0, 1 + this.endTimeAt - time);

        const gl = this.gl;
        gl.viewport(0, 0, this.width, this.height);
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        this.noise.bind(0);
        this.backgroundShader.bind(this.getNow(true), this.radar, this.mouse, { w: this.width, h: this.height }, this.targets);
        this.quadMesh.prepareDraw(this.backgroundShader.attributions.position);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        this.mouse.__prev.x = this.mouse.x;
        this.mouse.__prev.y = this.mouse.y;

        setTimeout(() => {
            requestAnimationFrame(__caller);
        }, 2);
    }
}


module.exports = ShootGame4;