const Shader = require("@/game/Shader");

class TextureShader extends Shader {
    __getShaders() {
        return {
            // language=Glsl
            vert:
                'precision highp float;attribute vec2 a;varying vec4 m;uniform vec4 b,c;uniform float e,f;' +
                'void main(){m=vec4(vec2(a.x,1.-a.y)*c.zw+c.xy,a),gl_Position=vec4((a*b.zw+b.xy)*vec2(1,e)*2.-1.,f,1);}',
            // language=Glsl
            frag:
                'precision highp float;varying vec4 m;uniform float e;uniform sampler2D i,n;uniform vec4 q,z,t;' +
                'mat2 r=mat2(.6,.8,-.8,.6);' +
                'float f(vec2 p){float a=texture2D(n,p).r*.5;p=r*p*2.01;a+=texture2D(n,p).r*.25;p=r*p*2.03;a+=texture2D(n,p).r*.125;p=r*p*1.99;a+=texture2D(n,p).r*.0625;return a;}' +
                'vec2 ff(vec2 p){return vec2(f(p), f(p+.171345));}' +
                'vec2 f2(vec2 p){vec2 a=texture2D(n,p).gb*.5;p=r*p*2.01;a+=texture2D(n,p).gb*.25;p=r*p*2.03;a+=texture2D(n,p).gb*.125;p=r*p*1.99;a+=texture2D(n,p).gb*.0625;return a;}' +
                'void main(){vec2 M=(m.xy*vec2(e,1.))*.1,A=f2(M+ff(M+vec2(sin(t.x*.01),cos(t.x*0.02)))*.2);gl_FragColor=vec4(texture2D(i,m.xy+A*.03*t.y).rgb,1.);}'
        }
    }

    bind(ar, img, n, t, P) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
        this.gl.uniform1i(this.uniforms.image, img);
        this.gl.uniform1i(this.uniforms.n, n);
        this.gl.uniform4f(this.uniforms.t, t * 0.3, P, 0, 0);
    }

    __initUniforms() {
        return {
            screenCrop: this.gl.getUniformLocation(this.program, 'b'),
            textureCrop: this.gl.getUniformLocation(this.program, 'c'),
            ar: this.gl.getUniformLocation(this.program, 'e'),
            z: this.gl.getUniformLocation(this.program, 'f'),
            image: this.gl.getUniformLocation(this.program, 'i'),
            n: this.gl.getUniformLocation(this.program, 'n'),
            t: this.gl.getUniformLocation(this.program, 't'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'a')
        }
    }

    bindUniforms(x, y, z, w, h, textureCrop, opaque, mixColor) {
        const gl = this.gl;
        gl.uniform1f(this.uniforms.z, -z / 10.);
        gl.uniform4f(this.uniforms.screenCrop, x, y, w, h);
        gl.uniform4f(this.uniforms.textureCrop, textureCrop.x, textureCrop.y, textureCrop.w, textureCrop.h);

    }
}


module.exports = TextureShader;