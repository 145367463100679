// noinspection PointlessBooleanExpressionJS

/*
def encode(s):
  s = s.encode('utf-8')
  return list(reversed(list(map(lambda x: x ^ (42 | (len(s) & 15)), s))))

def shuffle(arr):
  from random import randint
  s = set()
  seq = []
  for i in range(len(arr)):
    a = randint(0, len(arr) - 1)
    while a in s:
      a = randint(0, len(arr) - 1)
    s.add(a)
    seq.append(a)
  res = [randint(0, 255)]
  d = {}
  for i in range(len(seq)):
    d[seq[i]] = i
  for i in range(len(arr)):
    res.append(arr[seq[i]] ^ res[0])
    res.append(d[i] ^ arr[seq[i]] ^ (len(arr) & 15 | 41))
  return res
*/

/**
 * Encoded str to decoded
 * @param d encoded
 * @returns {string} decoded
 */
function d(d) {
    d.a = d.length;
    const qwr = !d + [];
    const qgp = {};
    qgp[(!![] + [])] = qwr[+!![]];
    qgp[![]] = ([![]] + [][[]])[+!+[] + [+[]]];

    qgp[3] = (!![] + [])[+!+[]];
    qgp[4] = qgp[3];
    qgp[-d.a] = 't';
    qgp[[][[]]] = qwr[4];
    const wwd = ([][[]] + []);
    qgp[0] = wwd;
    const zsd = 'co' + wwd[1] + 'st' + (true + [])[+!+[]] + 'uc' + (!![] + '')[+(d.a === 0)] + 'or'
    const asd = 'co' + wwd[2] + (!![] + [])[3] + (!![] + [])[1];
    const pqw = 'rn D' + (![] + '')[1];
    const qgb = qgp[3] + qwr[3] + qgp[''[1]];
    let wpq = '\\' + [+!+[]] + 36;
    let ajs = qgp[3] + qwr[4] + 't';
    const qsg = d['l' + qgp[[][1]] + qgp[0][1] + 'gth'];
    wpq = ([]['fl' + qgp[pqw !== qsg] + 't'][zsd](ajs + `ur${qgp[0][+true]} "` + wpq + '"'))();
    const qwe = 'D' + ([][[]] + [])[3];
    const ggr = '(n' + qwr[4] + 'w U';
    const sgd = 'A' + qgp[3] + qgp[4] + qgp[true] + 'y((i[\'' +
        `${qgp[4]}ev${qgp[[][[]]]}${qgb}']()).m${qgp[true]}p(e ${''[wwd[4] + 'on' + qgp[-d.a] + 'cilor'['repl' + qgp[!!d] + 'ce'](qgp[d.a < 1], 'o')]()[11]}> e`
        + wpq + `${qsg & [+!+[]] + 5 | 4 + [!+[] + !+[]]})` + '))';
    ajs = ([]['f' + ((qsg === 0) + '')[true + 1] + 'at'][zsd](ajs + (ajs + [][27])[3] + pqw + 'te'))()()[27];
    const wqs = !![] + [];
    const fio = wwd[8] + wwd[7];
    const aqq = (!![] + [])[3] + 'x' + wqs[0];
    const sdf = 'co' + fio + ggr + qgp[qsg < 1] + 'nt' + Math.pow(!+[] + !+[], !![] * 3) + sgd;
    const asf = ajs + aqq + qwe + asd;
    const grf = []['m' + (![] + [])[1] + 'p'][zsd];
    const pgr = 're' + wqs[0] + 'ur' + wwd[1] + ' ' + wwd[1] + wwd[3] + 'w ' + asf + '()' + (1e25 / +([+!+[]] + [+[]]) + [])[1];
    return grf(qgp[d.a === 0], pgr + fio + sdf)(d);
}

function z(_) {
    //document.getElementsByTagName('html')['item'].constructor
    let $ = d([93, 64, 91, 76, 90, 93, 91, 92, 65, 64, 76, 1, 66, 74, 91, 70, 1, 6, 8, 67, 66, 91, 71, 8, 7, 74, 66, 78, 97, 72, 78, 123, 86, 109, 92, 91, 65, 74, 66, 74, 67, 106, 91, 74, 72, 1,
        91, 65, 74, 66, 90, 76, 64, 75, 15, 65, 93, 90, 91, 74, 93]), t = {};
    return t[_] = d, t[!_] = "a", t[!!_] = (!!_ + [])[2], []["f" + t[!!_] + t[!_] + "t"][t[_]([93, 64, 65, 77]) + "t" + d([92, 65, 90, 77, 91, 92])]($)()
}

function sf() {
    // let _=[],e=t.length/2,n=15&e|41,f=0;for(;f<e-1;f++)_.push(t[(t[2*f+2]^n^t[2*f+1]^t[0])*2+1]^t[0]);return d(_);
    let _ = arguments;
    return z()('d', 't', d([21, 7, 113, 6, 74, 14, 64, 92, 91, 90, 75, 92, 21, 7, 115, 30, 117, 90, 112, 115, 31, 5, 28, 4, 7, 115, 30, 117, 90, 112, 115, 31, 5, 72, 4, 28, 117, 90, 112, 64,
        112, 115, 28, 5, 72, 4, 28, 117, 90, 6, 117, 90, 6, 70, 93, 91, 94, 0, 113, 7, 5, 5, 72, 21, 31, 3, 75, 18, 72, 21, 6, 92, 65, 72, 21, 30, 19, 72, 2, 31, 26, 82, 75, 8, 27, 31, 19, 64, 2, 28, 1, 70, 90,
        73, 64, 75, 66, 0, 90, 19, 75, 2, 115, 117, 19, 113, 14, 90, 75, 66]))(d, _[d([70, 90, 73, 64, 75, 66])] > 1 ? _ : _[0]);
}

function de() {
    return z()(d(Array.from(arguments)))()
}

/**
 * document.getElementById
 */
function ge() {
    //return document.getElementById(arguments[0])
    return z()(sf(105, 53, 86, 53, 98, 42, 124, 51, 126, 111, 50, 53, 101, 38, 67, 50, 110, 105, 47, 28, 83, 34, 119, 50, 115, 51, 113, 41, 105, 34, 120, 41, 113, 40, 70, 32, 70, 119, 22, 26, 83, 51, 125, 50, 105, 42,
        71, 35, 68, 34, 66, 52, 115, 32, 127, 34, 124, 14, 66, 34, 70, 35, 104, 42, 115, 51, 97, 41, 120, 5, 106, 43, 127, 2, 81, 51, 81, 103, 11, 110, 42, 41, 106, 34, 106, 62, 116, 36, 97))(sf(arguments));
}

function spar() {
    const arr = Array.from(arguments);
    const amount = arr[0];
    const lens = [];
    let i = 1;
    for (; i <= amount; i++)
        lens.push(arr[i]);
    const res = [];

    for (const len of lens) {
        const block = [];
        for (let j = 0; j < len; j++)
            block[j] = arr[i + j];
        i += len;
        res.push(block);
    }
    return res;
}

function zip() {
    const j = Array.from(arguments)
    const packs = [];
    for (let i = 0; i < j[0].length; i++) {
        const b = [];
        for (const a of j)
            b.push(a[i]);
        packs.push(b);
    }
    return packs;
}

let s = null;
let listener = () => {
};

function auth(token) {
    // fetch('http://127.0.0.1:9000/auth', {method: 'POST', body: JSON.stringify(token)}).then(j => {
    fetch('https://api.tir.rigellab.ru/auth', {method: 'POST', body: JSON.stringify(token)}).then(j => {
        j.json().then(e => {
            localStorage.setItem('key', e.token);
            authSocket(e.token);
        })
    });
}

function send(a, b) {
    // n=n instanceof ArrayBuffer?new Uint8Array(n):n;let $=new ArrayBuffer(n.byteLength+2),c=new Uint8Array($),d=Math.floor(245*Math.random()+10);c[0]=d,d=17*d+23&255,c[1]=e^d;
    // for(let f=0;f<n.byteLength;f++)d=17*d+23&255,c[2+f]=n[f]^d;try{o.send($)}catch(r){t(),setTimeout(()=>send(e,n),500)}

    return this.h || (this.h = z()('e', 'n', 't', 'o', d([83, 7, 30, 30, 27, 2, 7, 64, 2, 75, 6, 74, 64, 75, 93, 16, 19, 7, 6, 6, 90, 91, 65, 75, 67, 71, 122, 90, 75, 93, 2, 7, 6, 90, 85, 7, 92, 6, 70, 77, 90, 79,
        77, 83, 7, 10, 6, 74, 64, 75, 93, 0, 65, 85, 87, 92, 90, 21, 74, 112, 115, 72, 117, 64, 19, 115, 72, 5, 28, 117, 77, 2, 27, 27, 28, 8, 29, 28, 5, 74, 4, 25, 31, 19, 74, 7, 5, 5, 72, 21, 70, 90, 73, 64, 75, 98,
        75, 90, 87, 76, 0, 64, 18, 72, 21, 30, 19, 72, 14, 90, 75, 66, 6, 92, 65, 72, 21, 74, 112, 75, 19, 115, 31, 117, 77, 2, 27, 27, 28, 8, 29, 28, 5, 74, 4, 25, 31, 19, 74, 2, 74, 19, 115, 30, 117, 77, 21, 7, 30,
        31, 5, 7, 6, 67, 65, 74, 64, 79, 92, 0, 70, 90, 79, 99, 4, 27, 26, 28, 6, 92, 65, 65, 66, 72, 0, 70, 90, 79, 99, 19, 74, 2, 7, 10, 6, 87, 79, 92, 92, 111, 22, 90, 64, 71, 123, 14, 89, 75, 64, 19, 77, 2, 7, 28,
        5, 70, 90, 73, 64, 75, 98, 75, 90, 87, 76, 0, 64, 6, 92, 75, 72, 72, 91, 108, 87, 79, 92, 92, 111, 14, 89, 75, 64, 19, 10, 14, 90, 75, 66, 21, 64, 20, 7, 64, 6, 87, 79, 92, 92, 111, 22, 90, 64, 71, 123, 14, 89,
        75, 64, 17, 92, 75, 72, 72, 91, 108, 87, 79, 92, 92, 111, 14, 72, 65, 75, 77, 64, 79, 90, 93, 64, 71, 14, 64, 19, 64]))), this.h(a, b, k, s);
}

function p(_) {
    //let n=new Uint8Array(e),t=new DataView(new ArrayBuffer(n.length-1)),l=n[0];for(let _=1;_<n.length;_++)l=17*l+23&255,t.setUint8(_-1,n[_]^l);return t
    return this.u || (this.u = z()("e", sf(39, 59, 46, 105, 115, 61, 72, 34, 107, 126, 62, 61, 18, 123, 118, 36, 98, 49, 17, 126, 83, 52, 29, 98, 12, 38, 109, 109, 75, 39, 166, 120, 90, 49, 99, 126, 37, 105, 44, 99,
        232, 49, 4, 89, 222, 44, 162, 109, 106, 105, 17, 120, 97, 49, 70, 105, 21, 96, 55, 101, 5, 96, 78, 98, 7, 107, 12, 37, 166, 120, 244, 120, 60, 36, 72, 96, 29, 48, 114, 98, 35, 107, 107, 44, 32, 83, 13, 106,
        89, 55, 88, 98, 18, 98, 96, 126, 22, 72, 199, 33, 165, 105, 42, 126, 125, 55, 19, 98, 73, 36, 21, 77, 199, 101, 34, 36, 113, 109, 71, 96, 58, 109, 23, 105, 96, 96, 118, 49, 110, 105, 76, 32, 110, 120, 102,
        44, 62, 105, 23, 126, 99, 98, 125, 81, 215, 42, 18, 33, 88, 126, 1, 34, 89, 121, 45, 96, 124, 39, 16, 100, 60, 105, 56, 100, 15, 61, 51, 83, 110, 32, 125, 120, 12, 83, 127, 61, 181, 98, 42, 98, 114, 126,
        113, 127, 53, 44, 22, 37, 26, 32, 165, 121, 249, 63, 24, 105, 58, 82, 56, 96, 18, 39, 17, 90, 82, 78, 109, 37, 185, 44, 99, 32, 66, 98, 92, 57, 164, 34, 41, 120, 114, 123, 22, 117, 65, 105, 0, 62, 63, 36,
        186, 60, 39, 101, 89, 81, 53, 55, 65, 87, 64, 120, 107, 98, 102, 123, 26, 120, 53, 37, 84, 37, 49, 117, 93, 120, 27, 62, 14, 89, 92, 120, 46, 83, 104, 98, 100, 123, 34, 96, 6, 83, 66, 55, 186, 105, 235,
        106, 227, 52, 104, 106, 75, 87, 79, 44, 21, 57, 105, 77, 62, 98, 46, 120, 74))), this.u(_)
}

function authSocket(key) {
    send(5, new Uint8Array(new TextEncoder().encode(key)))
}


function k() {
    if (s)
        s.close();

    // s = de(2, 12, 95, 78, 64, 72, 68, 88, 73, 78, 92, 12, 11, 7, 12, 95, 78, 64, 72, 68, 88, 73, 78, 92, 6, 78, 70, 74, 76, 6, 89, 66, 95, 4, 94, 89, 5, 73, 74, 71, 71, 78, 76, 66, 89, 5, 89, 66, 95, 5, 66, 91,
    //     74, 4, 4, 17, 88, 88, 92, 12, 3, 95, 78, 64, 72, 68, 120, 73, 78, 124, 11, 92, 78, 69, 11, 69, 89, 94, 95, 78, 89);
    s = new WebSocket('wss://api.tir.rigellab.ru/tir-game-websocket', 'websocket')
    // s = new WebSocket('ws://127.0.0.1:9001/tir-game-websocket', 'websocket')

    // onopen, onerror, onmessage, onclose
    for (const [e, f] of zip([
        _ => localStorage.getItem('key') ? authSocket(localStorage.getItem('key')) : auth(null),
        _ => setTimeout(k, 1000),
        _ => listener(_),
        _ => s.close()
    ], spar(4, 6, 7, 9, 7, 64, 75, 94, 65, 64, 65, 74, 92, 64, 67, 76, 65, 64, 78, 76, 74, 88, 88, 78, 70, 69, 68, 93, 64, 93, 93, 74, 65, 64)))
        s[d(f)] = e;
}

k();

function setListener(l) {
    listener = l;
}


module.exports = {setListener, send, d, ge, sf, p};