const Shader = require("@/game/Shader");

class TraceSpaceShader extends Shader {
    __getShaders() {
        return {
            // language=Glsl
            vert:
                'precision highp float;attribute vec2 f;uniform vec4 b;uniform float d;varying vec2 e;' +
                'void main(){e=f*2.-1.;float a=sin(b.w),c=cos(b.w);mat2 g=mat2(c,a,-a,c);gl_Position=vec4(b.xy*vec2(2,2.*d)-1.+g*(vec2(b.z,.04)*e)*vec2(1,d),0,1);}'
            /*`precision highp float;
            attribute vec2 l;
            uniform vec4 a;
            uniform float y;
            varying vec2 p;
            void main() {
                p = l * 2. - 1.;
                float r = sin(a.w), w = cos(a.w);
                float u = .01;
                mat2 rt = mat2(w, r, -r, w);
                gl_Position = vec4(a.xy * vec2(2., 2. * y) - 1. + rt * (vec2(a.z, u) * p) * vec2(1., y), 0.0, 1.0);
            }`*/,
            // language=Glsl
            frag:
                'precision mediump float;varying vec2 e;uniform float c;uniform float t;uniform sampler2D n;' +
                'mat2 r=mat2(.6,.8,-.8,.6);' +
                'float f(vec2 p){float a=texture2D(n,p).r*.5;p=r*p*2.01;a+=texture2D(n,p).r*0.25;p=r*p*2.03;a+=texture2D(n,p).r*0.125;p=r*p*1.99;a+=texture2D(n,p).r*0.0625;return a;}' +
                'void main(){float d=smoothstep(.9,.5,length(vec2((e.x*.5+.5-c)*4., e.y)))*smoothstep(1.,1.-.1/c,e.x);vec2 E=e*vec2(1.,.1)*0.02+t*0.0001;' +
                'float a=f(E),b=f(E+.17),q=f(E*.2+vec2(a,b));gl_FragColor=vec4(vec3(.8,.2,.9),q*d);}'
            /*`precision mediump float;
              varying vec2 p;
              uniform float q;
              void main() {
                  float k = q * 2.;
                  float s = (1. - abs(p.x - k)) * (1. - abs(p.y)) * smoothstep(1., 1. - (.02 / q), p.x);
                  gl_FragColor = vec4(s);
              }`*/
        }
    }

    bind(ar, n) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
        this.gl.uniform1i(this.uniforms.n, n);
    }

    __initUniforms() {
        return {
            params: this.gl.getUniformLocation(this.program, 'b'),
            ar: this.gl.getUniformLocation(this.program, 'd'),
            n: this.gl.getUniformLocation(this.program, 'n'),
            percent: this.gl.getUniformLocation(this.program, 'c'),
            time: this.gl.getUniformLocation(this.program, 't'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'f')
        }
    }

    bindUniforms(x, y, length, rotate, percent, time) {
        const gl = this.gl;
        gl.uniform4f(this.uniforms.params, x, y, length, rotate);
        gl.uniform1f(this.uniforms.percent, percent);
        gl.uniform1f(this.uniforms.time, time);
    }

}

module.exports = TraceSpaceShader