<template>
    <div>
        <div style="user-select: none" class="game-3">
            <div style="width: 100%;height: 3vw;background-image: url(/tir3/top.png);display: block;position: relative">
                <input @click="start" type="button" value="start">
                <img style="display: block;height: 2vw;top:.5vw;position: absolute;right: 5vw" src="/tir3/close.png">
            </div>
            <div id="shootGame2-holder">
                <div style="position: relative;width: 0;height: 0" id="game-3-onscreen" class="onscreen"/>
                <div style="position: relative;width: 100%;height: 0;">
                    <div style="position: absolute;width: 100%;display: flex;justify-content:center;height: 3vw;top:.5vw">
                        <div style="height: 3vw;width:10vw;position:relative;">
                            <img style="display: block;height: 3vw;" src="/tir3/info.png">
                            <div style="font-size: 2vw;position:absolute;top:.5vw;right:1.8vw;font-weight: 500;color: #24FF00" id="game-3-targets">20</div>
                        </div>
                    </div>
                </div>
                <canvas id="shootGame2" style="margin: 0;padding: 0;flex: 0 0"/>
            </div>
            <div class="bottom-game">
                <img src="/tir3/bottom.png" class="bg-img">
                <div class="content">
                    <div class="element" style="flex: 0 0 24vw;justify-content: space-around;align-items: center">
                        <div style="flex: 0 0 8vw">
                            <div style="color: #ffffff;font-size: 1.3vw;margin-bottom: .7vw">Ammo:</div>
                            <div style="display: flex;align-items: end">
                                <div style="font-size: 2.3vw;color: #FFE500" id="game-3-ammo">20</div>
                                <div style="color: #888888;font-size: 1.3vw">/20</div>
                            </div>
                        </div>
                        <div style="flex: 0 0 13vw;display:flex;flex-wrap: wrap;align-items: center;justify-content: space-around">
                            <img style="display: block;width: 1vw;margin-left:.29vw;margin-top:.24vw;height: 2.1vw" src="/tir3/bullet.png" v-for="i in 20">
                        </div>
                    </div>
                    <div class="element" style="flex: 0 0 17vw;flex-direction: column;justify-content: center">
                        <div style="font-size: .9vw;color: #ffffff;margin-bottom: .5vw">Среднее время тарелки на поле:</div>
                        <div style="color: #24FF00;font-size: 1.5vw"><span id="game-3-reaction">0.00</span> м/с</div>
                    </div>
                    <div class="element" style="flex: 0 0 12vw;align-items: center">
                        <img src="/tir3/success.png" style="display: block;flex: 0 0 5vw;height: 5vw">
                        <div style="display: flex;flex-direction: column">
                            <div style="color: #ffffff;font-size: 1vw;margin-bottom: .5vw">Points:</div>
                            <div style="display: flex;justify-content: space-around;align-items: center">
                                <div style="font-size: 1.5vw;color: #ffffff">5</div>
                                <div style="font-size: 1vw;color: #24FF00">x</div>
                                <div style="font-size: 1vw;color: #24FF00" id="game-3-success">0</div>
                            </div>
                        </div>
                    </div>
                    <div class="element" style="flex: 0 0 12vw;align-items: center">
                        <img src="/tir3/miss.png" style="display: block;flex: 0 0 5vw;height: 5vw">
                        <div style="display: flex;flex-direction: column">
                            <div style="color: #ffffff;font-size: 1vw;margin-bottom: .5vw">Points:</div>
                            <div style="display: flex;justify-content: space-around;align-items: center">
                                <div style="font-size: 1.5vw;color: #ffffff">-5</div>
                                <div style="font-size: 1vw;color: #FF0000">x</div>
                                <div style="font-size: 1vw;color: #FF0000" id="game-3-miss">0</div>
                            </div>
                        </div>
                    </div>
                    <div class="element" style="flex: 0 0 15vw;align-items: center">
                        <div style="color: #ffffff;font-size: 1.5vw">POINTS:</div>
                        <div style="color: #24FF00;font-size: 2.5vw;margin-left: .7vw" id="game-3-score">0</div>
                    </div>
                </div>
            </div>
        </div>
        <Shop ref="shop"/>
    </div>
</template>

<script>
import ShootGame3 from "@/game/shootGame3";
import Shop from "@/Shop.vue";

export default {
    components: { Shop },
    data: () => ({
        game: null,
    }),
    methods: {
        start() {
            this.game.wantStart();
        },
    },
    mounted() {
        this.game = new ShootGame3(this.$refs.shop.onResult);
    },
    beforeUnmount() {
        this.game.finish();
    }
}
</script>

<style lang="less">

@keyframes shootGame3-info-hide {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-1vw);
        opacity: 0;
    }
}

.game-3 {
    display: flex;
    flex-direction: column;
    font-family: 'BetterVcr', serif;

    #shootGame2-holder {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .onscreen {
            .block {
                display: flex;
                width: 4vw;
                height: 4vw;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute;
                text-align: center;
                animation-duration: 900ms;
                animation-name: game-4-score;
                animation-fill-mode: forwards;
                color: #ffffff;
                text-shadow: 0 0 0.2vw #0005;

                .time {
                    font-size: 0.8vw;
                }

                .text {
                    font-size: 2vw;
                }

                .red {
                    color: #FF0C25;
                }

                .green {
                    color: #13D93E;
                }
            }

            .hide-info {
                animation-duration: .3s;
                animation-name: shootGame2-info-hide;
                animation-fill-mode: forwards;
            }

            .info {
                position: absolute;
                display: flex;
                flex-direction: column;
                width: 7vw;
                font-size: .7vw;
                color: #ffffff;
                text-shadow: 0 0 0.2vw #0005;

                .line {
                    height: .1vw;
                    width: 100%;
                    position: relative;
                    background: #000000;
                    margin: .2vw 0;

                    .dot {
                        position: absolute;
                        height: .6vw;
                        width: .6vw;
                        right: -.2vw;
                        top: -.25vw;
                        background: #000000;
                    }
                }
            }
        }
    }

    .bottom-game {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 8.4vw;
        background: #1F2F43;
        display: flex;
        justify-content: space-around;
        position: relative;

        .bg-img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .content {
            position: absolute;
            width: 94%;
            left: 3vw;
            height: 70%;
            top: 15%;
            display: flex;
            justify-content: space-between;
        }

        .element {
            border: .2vw solid #626262;
            box-sizing: border-box;
            padding: 0 1vw;
            display: flex;
            background: rgba(0, 0, 0, 0.5);
        }
    }
}
</style>