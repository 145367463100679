const Shader = require("@/game/Shader");

class TraceShader extends Shader {

    __getShaders() {
        return {
            // language=Glsl
            vert:
                'precision highp float;attribute vec2 f;uniform vec4 b;uniform float d;varying vec2 e;' +
                'void main(){e=f*2.-1.;float a=sin(b.w),c=cos(b.w);mat2 g=mat2(c,a,-a,c);gl_Position=vec4(b.xy*vec2(2,2.*d)-1.+g*(vec2(b.z,.01)*e)*vec2(1,d),0,1);}'
            /*`precision highp float;
            attribute vec2 l;
            uniform vec4 a;
            uniform float y;
            varying vec2 p;
            void main() {
                p = l * 2. - 1.;
                float r = sin(a.w), w = cos(a.w);
                float u = .01;
                mat2 rt = mat2(w, r, -r, w);
                gl_Position = vec4(a.xy * vec2(2., 2. * y) - 1. + rt * (vec2(a.z, u) * p) * vec2(1., y), 0.0, 1.0);
            }`*/,
            // language=Glsl
            frag:
                'precision mediump float;varying vec2 e;uniform float c;void main(){float b=c*2.,d=(1.-abs(e.x-b))*(1.-abs(e.y))*smoothstep(1.,1.-.02/c,e.x);gl_FragColor=vec4(d);}'
            /*`precision mediump float;
              varying vec2 p;
              uniform float q;
              void main() {
                  float k = q * 2.;
                  float s = (1. - abs(p.x - k)) * (1. - abs(p.y)) * smoothstep(1., 1. - (.02 / q), p.x);
                  gl_FragColor = vec4(s);
              }`*/
        }
    }

    bind(ar) {
        super.bind();
        this.gl.uniform1f(this.uniforms.ar, 1. / ar);
    }

    __initUniforms() {
        return {
            params: this.gl.getUniformLocation(this.program, 'b'),
            ar: this.gl.getUniformLocation(this.program, 'd'),
            time: this.gl.getUniformLocation(this.program, 'c'),
        }
    }

    __initAttributions() {
        return {
            position: this.gl.getAttribLocation(this.program, 'f')
        }
    }

    bindUniforms(x, y, length, rotate, time) {
        const gl = this.gl;
        gl.uniform4f(this.uniforms.params, x, y, length, rotate);
        gl.uniform1f(this.uniforms.time, time);
    }

}

module.exports = TraceShader